<template>
    <div id="app">
        <div class="header u-flex u-row-right">
            <el-dropdown size="mini" @command="handleCommand" trigger="click" v-if="!show || languageShow">
                <span class="el-dropdown-link">
                    {{ getLocaleName(locale) }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.stop="" v-for="(item, index) in languageList" :command="index" divided
                                      v-show="item.show" :key="index">{{ item.text }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            languageList: [],
            locale: "",
            show: false,
            languageShow: false,
        };
    },
    mounted() {
        this.getLanguageList();
        this.show = this.$store.state.nexShow;
    },
    methods: {
        getLanguageList() {
            let languageList = this.$store.state.BaseData.languageList;
            this.languageShow = this.$store.state.BaseData.languageShow;
            this.languageList = languageList;
            this.locale = this.$i18n.locale;
            document.title = this.$t("title");
            this.store();
        },
        getLocaleName(code) {
            let name = "Language";
            this.languageList.forEach((item) => {
                if (item.code === code) {
                    name = item.text;
                }
            });
            return name;
        },
        handleCommand(index) {
            let data = this.languageList[index];
            this.$i18n.locale = data.code;
            this.locale = data.code;
            localStorage.setItem("locale", data.code);
            document.title = this.$t("title");
            this.store();
        },
        store() {
            let navList = [
                {
                    img: require("@/assets/imgs/im916.png"),
                    name: "imToken",
                    nickname: this.$t(`wallet.imToken`),
                    disabled: false,
                    value: 1,
                    payType: this.$t("nav.type"),
                    type: "trx",
                },
                {
                    img: require("@/assets/imgs/tp916.png"),
                    name: "TokenPocket",
                    nickname: this.$t(`wallet.TokenPocket`),
                    disabled: false,
                    value: 2,
                    payType: this.$t("nav.type"),
                    type: "trx",
                },
                {
                    img: require("@/assets/imgs/trustwallet.png"),
                    name: "trustapp",
                    nickname: this.$t(`wallet.trustwallet`),
                    disabled: false,
                    value: 3,
                    payType: this.$t("nav.type") + `(ERC20)`,
                    type: "trx",
                },
                // {
                //   img: require("@/assets/imgs/bite916.jpg"),
                //   name: "BITPIE",
                //   nickname: this.$t(`wallet.BITPIE`),
                //   disabled: false,
                //   value: 4,
                //   payType: this.$t(`nav.type1`),
                //   type: "trx",
                // },
                {
                    img: require("@/assets/imgs/tronlink916.jpg"),
                    name: "TronLink",
                    nickname: this.$t(`wallet.TronLink`),
                    disabled: false,
                    value: 5,
                    payType: this.$t(`nav.type2`, {name: "TRC20"}),
                    type: "trx",
                },
                {
                    img: require("@/assets/imgs/bitkeep916.jpg"),
                    nickname: "BitKeep",
                    name: this.$t(`wallet.BitKeep`),
                    disabled: false,
                    value: 6,
                    payType: this.$t(`nav.type1`),
                    type: "trx",
                },
                // {
                //   img: require("@/assets/imgs/okx.png"),
                //   name: "OKX",
                //   nickname: this.$t(`wallet.OKX`),
                //   disabled: false,
                //   value: 8,
                //   payType: this.$t(`nav.type1`) + "(OKC)",
                //   type: "eth",
                // },
            ]
            this.$store.commit("navList", navList);
        }
    },
    watch: {
        "$store.state.nexShow"(val) {
            this.show = val;
        },
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

.header {
    max-width: 750px;
    left: 0;
    right: 0;
    background: #efeff4;
    margin: 0 auto;
    padding: 10px 20px;
    box-sizing: border-box;
}
</style>
