/*
 * @Author: your name
 * @Date: 2020-10-28 11:44:21
 * @LastEditTime: 2020-12-04 13:38:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \place_an_order_admin\src\utils\axios.js
 */
import axios from "axios";
// import router from "@/router";
// import store from "@/store";

// if (
//   process.env.NODE_ENV == "development" ||
//   process.env.NODE_ENV == "test" ||
//   process.env.NODE_ENV == "production"
// ) {
//   let BaseUrl = "https://pr.token1n.xyz";
//   axios.defaults.baseURL = BaseUrl;
// }

const httpHelper = {};

httpHelper.install = function fn(Vue) {
    axios.interceptors.request.use(
        function (config) {
            config.headers["Content-Type"] = "application/json";
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    Vue.prototype.$http = axios;
};
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (res) => {
        let msg = "";
        const {response, message} = res;

        // 错误类型判断
        if (response) {
            const {Message, Form} = response.data || response;
            if (Message && Message.length > 0) {
                msg = Message[0];
            } else if (Form && Form["Entity.Import"]) {
                console.log("--");
                return;
            } else if (Form && Form !== {}) {
                const cxts = Object.keys(Form).map((key) => Form[key]);
                msg = cxts.join(",");
            } else {
                msg = response.data;
            }
        } else if (message) {
            msg = message;
        }
        if (msg) {
            console.log(msg);
        }
        if (res.response) {
            console.log(res.response.data);
            return res.response.data;
        } else {
            return {};
        }
        // return Promise.reject(res.response);
    }
);

export default httpHelper;
