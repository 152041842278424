<!--  -->
<template>
  <div class="privacy">
    <h1>《TokenPocket Privacy Policy》</h1>
    <h2>Last Updated: May 29, 2021.</h2>

    <p>
      Dear Users,<br />
      TokenPocket Foundation Ltd. &amp; PTS Global(HonKong)Limited(“PTS”or“TPF”
      or “we”) respects and protects the priva cy of Users (“you” or “Users”).
      TPF will collect and use your Personal Information, generated from your
      use of TokenPocket in accordance with this Privacy Policy (“Policy”).
      <br />
      TPF &amp; PTS recommends that you shall carefully read and understand the
      whole contents of this Policy before your use of the product
      (“TokenPocket”). Additionally, significant information including the
      Disclaimer is in bold form in this Policy. Definitions of key words in
      this Policy are consistent with those in the TokenPocket Terms of Service
      of TPF.<br />
      <br />
      TPF reserves the right to update this Policy online from time to time, and
      the new policy will immediately replace the older one once posted. In
      particular, if you do not accept the revised policies, please immediately
      stop your use of TokenPocket. Your continuous use of TokenPocket will be
      regarded as your acceptance of the revised policy. Furthermore, the
      revised policies will take into effect immediately upon being posted on
      TokenPocket.<br />
      You acknowledge and accept that this Policy and other relevant rules apply
      to TokenPocket and the DApps owned by TPF on TokenPocket.<br /><br />
    </p>
    <h3>Information We Collect</h3>
    <p>
      We collect your information in order to provide the services you requested
      on TokenPocket, and we highly value the protection for your privacy. We
      strictly abide by the principle of “lawful, justifiable and necessary”
      during our collection of your information. You acknowledge that, if you do
      not provide us with necessary information, your user experiences on
      TokenPocket may be influenced.<br />
      We may collect your Personal Information, including but not limited to
      your mobile device information, operation records, transaction records and
      wallet addresses.<br />
      &nbsp;&nbsp; 1.1 In order to satisfy your needs for specific services, we
      may collect your name, bank card number, telephone number (Mobile phone
      number registration method), email address (Email registration method),
      etc.<br />
      &nbsp;&nbsp; 1.2 You confirm that your Wallet Password, Private Key,
      Mnemonic Words, Keystore on TokenPocket (the decentralized solution) are
      not stored or synchronized on TPF’ servers. TPF does not offer the service
      to restore your Wallet Password, Private Key, Mnemonic Words or
      Keystore.<br />
      &nbsp;&nbsp; 1.3 Apart from the foregoing provisions, when you are using
      specific function of TokenPocket, we may give you special notification to
      ask for more Personal Information.<br />
      &nbsp;&nbsp; 1.4 After you link to the third-party-developed DApps from
      TokenPocket, your Personal Information will be collected and held by the
      third-party-developed DApps instead of being collected or held by
      TokenPocket. When you fill in your Personal Information on a
      third-party-developed DApp, you should pay attention to the Terms of Use
      and Privacy Policy of the third-party-developed DApp. We are not
      accountable for any losses caused by your operation on the
      third-party-developed DApp.<br />
      &nbsp;&nbsp; 1.5 To the extent permitted by laws and regulations, TPF may
      collect and use following Personal Information without your prior consent
      or authorization:<br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <b
        >a. information related to national security and national defense;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; b. information related to public security,
        public health, significant public interests;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; c. information related to criminal
        investigation, prosecution, trial and enforcement;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; d. the Personal Information collected is
        publicized by yourself;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; e. the Personal Information is collected from
        legally publicly disclosed information, such as legal news reports,
        government information disclosure and other channels;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; f. the Personal Information is necessary to
        maintain the security and compliance of services, such as to detect or
        to solve the malfunction of products and services;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; g. other circumstances prescribed by laws and
        regulations. We collect information in the following ways:<br
      /></b>
      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 1). Information you give us. For
      example, you fill in your name, telephone number, or bank card number in
      “My Account” column or you provide your email address when submitting
      feedbacks, or you give extra information to us when you use our specific
      services.<br />
      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 2). we collect information during
      your use of TokenPocket, including your mobile devices information and
      your operation records on TokenPocket, etc.<br /><br />
    </p>
    <h3>How We Use Your Information</h3>
    <p>
      &nbsp;&nbsp; 2.1 We may associate you with your wallet by the unique
      serial number of your mobile device.<br />
      &nbsp;&nbsp; 2.2 We may promptly push important notifications to you, such
      as software update, update of Terms of Service and this Policy.<br />
      &nbsp;&nbsp; 2.3 We offer you the “Touch ID” option in the “Settings”
      column of TokenPocket to provide you with a convenient and safe way to
      manage your digital Tokens.<br />
      &nbsp;&nbsp; 2.4 We deal with your feedbacks by using the Wallet Address
      and the mobile device information provided by you.<br />
      &nbsp;&nbsp; 2.5 We may collect your Personal Information to conduct our
      internal audit, data analysis and research etc. to enhance our
      services.<br />
      &nbsp;&nbsp; 2.6 According to TokenPocket Terms of Service and other rules
      of TPF, TPF will manage and handle the use behaviors of Users through
      Users’ information.<br />
      &nbsp;&nbsp; 2.7 We may use your information in accordance with laws,
      regulations and to cooperate with regulatory authorities.<br /><br />
    </p>
    <h3>How You Control Your Own Information</h3>
    <p>
      You are entitled to control your Personal Information provided to
      TokenPocket:<br />
      &nbsp;&nbsp; 3.1 You may import your other wallets into TokenPocket
      through synchronization of wallets and you may export your wallets from
      TokenPocket to other Tokens management wallets.<br />
      &nbsp;&nbsp; 3.2 TokenPocket will display the information of imported
      wallets to you. You may add or delete Tokens, transfer and collect Tokens
      using the “Assets” column.<br />
      &nbsp;&nbsp; 3.3 You understand that you may handle the following
      operations in the “Me” column on TokenPocket:<br />
      &nbsp;&nbsp; &nbsp;&nbsp; a. in the “Address Book” column, you may view
      and edit your “Contacts”;<br />
      &nbsp;&nbsp; &nbsp;&nbsp; b. in the “Settings” column, you may choose not
      to open “Unlock Setting”, which means you may refuse to use the
      verification service to enter TokenPocket;<br />
      &nbsp;&nbsp; &nbsp;&nbsp; c. in the “Profile” column, you do not have to
      provide your name, telephone number, bank card number information. But if
      you wish to use specific services, you may need to provide the foregoing
      information;<br />
      &nbsp;&nbsp; &nbsp;&nbsp; d. in the “Help” column, you may submit any
      questions or suggestions for TokenPocket at any time. We are glad to
      communicate with you to improve our services.<br />
      &nbsp;&nbsp; 3.4 When we collect information from you for a specific
      purpose, we will ask for your consent in advance to which you are entitled
      to refuse. However, you understand that when you refuse to give such
      consent, you also give up the choice to use specific services provided by
      TokenPocket.<br />
      &nbsp;&nbsp; 3.5 You acknowledge that since blockchain is an open source
      system, your transaction records are automatically public and transparent
      in the whole blockchain.<br />
      &nbsp;&nbsp;
      <b
        >3.6 You understand that after you link to the third-party-developed
        DApps from TokenPocket, the TokenPocket Terms of Service and TokenPocket
        Privacy Policy will no longer apply. You are encouraged to carefully
        review its privacy policies and related terms of service before you
        start to use the third-party-developed DApps.<br
      /></b>
      &nbsp;&nbsp; 3.7 You are entitled to ask us to update, revise, and delete
      your relevant information.<br />
      &nbsp;&nbsp; 3.8 We reserve the right to collect your information without
      your prior authorization or consent according to the Article 7 of Section
      1 of this Policy.<br /><br />
    </p>
    <h3>Information We may Share or Transfer</h3>
    <p>
      &nbsp;&nbsp; 4.1 The Personal Information collected by TPF for business
      and legal purposes<br />
      &nbsp;&nbsp; 4.2 The Personal Information will not be sold, traded or
      transferred to the third parties without your consent.<br />
      &nbsp;&nbsp; 4.3 The Personal Information may be disclosed to these
      entities if you agree to our disclosure of the personal information to
      strategic partners or related parties. These entities will only use your
      personal information for the purposes you have agreed to.<br />
      &nbsp;&nbsp; 4.4 You agree that we may disclose or share your personal
      information to the following third parties: &nbsp;&nbsp; &nbsp;&nbsp; a.
      service providers and data processors who represent us and provide
      services to us, such as KYC inspections, accounting, data processing or
      management services, website hosting, maintenance and operation services,
      mail information services, analysis services, and payment transaction
      processing, marketing, etc.; &nbsp;&nbsp; &nbsp;&nbsp; b. TPF consultants
      and professional consultants (such as accountants, lawyers, auditors).<br />
      &nbsp;&nbsp; 4.5 The Personal Information may be transferred to any other
      country for the above-mentioned purposes. TPF will obtain your consent and
      ensure that the recipient of personal information has the same level of
      personal information protection measures as TPF. If there is no personal
      information protection law applicable to the relationship between TPF and
      you in these countries or regions, we will sign a legally enforceable
      agreement with the receivers of personal information.<br />
      &nbsp;&nbsp;
      <b
        >4.6 TPF will not share with or transfer your Personal Information to
        any third party without your prior consent, except for the following
        circumstances:<br />
        &nbsp;&nbsp; &nbsp;&nbsp; a. the collected Personal Information is
        publicized by yourself;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; b. the Personal Information is collected from
        public information which was legally disclosed, such as news (lawfully
        reported), government information disclosure and other channels;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; c. we may share your Personal Information with
        our affiliates only when necessary and to the extent permitted by this
        Policy;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; d. in order to abide by applicable laws,
        regulations, legal procedures, and administrative or judiciary
        authorities, TPF will provide or implement this Policy to protect our or
        other parties' rights, assets or security;<br />
        &nbsp;&nbsp; &nbsp;&nbsp; e. in the case of mergers and acquisitions, if
        transfer of Personal Information is involved, TPF may require the
        receivers of Personal Information to be continuously bound by this
        Policy.<br /><br
      /></b>
    </p>
    <h3>How We Collect Data Automatically</h3>
    <p>
      &nbsp;&nbsp;
      <b
        >5.1 We use automatic data collection technology in the application.
        E.g:<br />
        &nbsp;&nbsp; &nbsp;&nbsp; a. cookies (or browser cookies). Cookies are
        small text files set by the operator of a website or application to
        identify your browser or device. We may use cookies on our apps to store
        and track information, such as the number and frequency of use of users,
        user distribution, and users' online preferences. Cookies do not capture
        information that can identify you, but the information they collect can
        help us analyze the usage of the application to improve your experience.
        You can turn off cookies in your browser settings. However, this may
        affect the functionality of the application.<br />
        &nbsp;&nbsp; &nbsp;&nbsp; b. network analysis. Web analytics is a method
        of collecting and evaluating the operation of visitors to web pages and
        mobile applications. This includes analyzing traffic patterns, such as
        determining the frequency of visits to certain functions of a website or
        mobile application, or to understand the information or services that
        visitors are most interested in. We use network analysis services
        provided by third-party tools.<br /><br
      /></b>
    </p>
    <h3>How We Protect Your Information</h3>
    <p>
      &nbsp;&nbsp; 6.1 If TPF terminates operation, TPF will stop the collection
      of your Personal Information in time, post the announcement on TokenPocket
      and delete or anonymize your Personal Information held by us within a
      reasonable period.<br />
      &nbsp;&nbsp; 6.2 To protect your Personal Information, TPF may adopt data
      security techniques, improve internal compliance levels, provide security
      training for our staff, and set security authority for access to relevant
      data to protect your Personal Information.<br />
      &nbsp;&nbsp; 6.3 We will send you messages about information security in
      the “Wallet Guide” column and update articles concerning the use of
      wallets and information protection in the “Wallet Guide” column on
      TokenPocket for your reference.<br /><br />
    </p>
    <h3>Protection for the Minors</h3>
    <p>
      The following special provisions apply to minors who are under the age of
      18 years old:<br />
      &nbsp;&nbsp; 7.1 The minors shall not use TokenPocket without the guidance
      from their parents or guardians.<br />
      &nbsp;&nbsp; 7.2 The parents and guardians of the minors shall provide
      guidance to the minors on using TokenPocket after they read this Policy,
      TokenPocket Terms of Service and other relevant rules.<br />
      &nbsp;&nbsp; 7.3 TokenPocket will ensure the confidentiality and security
      of the minors’ Personal Information in accordance with national laws and
      regulations.<br /><br />
    </p>
    <h3>Disclaimer</h3>
    <p>
      &nbsp;&nbsp;
      <b
        >8.1 After you link to the third-party-developed DApps from TokenPocket,
        the privacy policies of the third-party-developed DApps will apply. The
        collection and use of your Personal Information by the
        third-party-developed DApps will be controlled neither by TPF, nor by
        this Policy. &nbsp;&nbsp; 8.2 You shall carefully select and use the
        third-party-developed DApps and protect your Personal Information. TPF
        shall not be held liable for the privacy protection obligation of any
        third-party-developed DApps.<br />
        &nbsp;&nbsp; 8.3 You acknowledge and accept that, to the maximum extent
        permitted by applicable law, TPF will adopt measures as reasonable as
        possible to protect your Personal Information under current techniques
        on an “as is”, “as available” and “with all faults” basis, to avoid the
        disclosure, tampering or damage of information. since TPF transfers data
        wirelessly, TPF makes no guarantee on the privacy and security of
        wireless internet data transferring.<br /><br
      /></b>
    </p>
    <h3>Miscellaneous</h3>
    <p>
      &nbsp;&nbsp; 9.1 As a company that provides services to you, we will not
      actively provide your Personal Information to third parties other than the
      agreement, unless we obtain your consent and inform the other party's
      identity, contact information, processing purpose, processing method, etc.
      If there is service demand of processing your personal sensitive
      information in the future, we will ask your opinions once again, and use
      it after obtaining your consent.<br />
      &nbsp;&nbsp; 9.2 If you are a user outside of Singapore, you shall fully
      understand and conform to the laws, regulations and rules in your
      jurisdictions which are relevant to use of TPF. &nbsp;&nbsp; 9.3 During
      your use of TPF services, if you come across any problems related to your
      Personal Information use, you may contact us through the submission of
      your feedbacks on TokenPocket.<br />
      &nbsp;&nbsp; 9.4 You may access this Policy and other service rules of TPF
      on TokenPocket.
      <b
        >We encourage you to check the Terms of Service and Privacy Policy of
        TPF each time you log into TokenPocket.</b
      ><br />
      &nbsp;&nbsp; 9.5 This English version and other translated versions of
      this Policy are provided for the convenience of Users, and are not
      intended to revise the original Chinese version of this Policy. If there
      is any discrepancy between the Chinese version and non-Chinese version of
      this Policy, the Chinese version shall prevail.<br /><br />

      <b>This Policy shall become effective on January 10, 2018.<br /><br /></b>

      <b>
        As for any issues not covered in this Policy, you shall comply with the
        announcements and relevant rules as updated by TPF from time to time.<br /><br
      /></b>

      TokenPocket Foundation Ltd.
    </p>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {},
  watch: {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
.privacy {
  margin: 10px 0 30px;
  padding: 10px;
  color: #333;
  h1 {
    font-size: 1.2em;
    text-align: center;
    line-height: 3;
  }
  h2 {
    font-size: 0.8em;
    text-align: center;
    line-height: 2;
  }
  h3 {
    font-size: 1em;
    font-weight: bold;
    margin: 10px 0;
  }
  h4 {
    font-size: 0.8em;
    margin: 0 0 6px;
  }
  ul {
    list-style: disc;
    padding-left: 15px;
    line-height: 1.5;
  }
}
</style>