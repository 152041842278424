export default {
  title: "USDT轉帳",
  nav: {
    type: "快捷支付",
    type1: "手動支付",
    type2: "手動支付（{name}）",
    type3: "暫未開發",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamask",
    TokenPocket: "TokenPocket",
    trustwallet: "Trust Wallet",
    OKX: "歐易（OKEX）",
  },
  btn: {
    b1: "點擊支付",
    b2: "下一步",
    b3: "複製",
  },
  hint: {
    h1: "由於協力廠商充值風控限制，現時只能USDT充值，請針對相應錢包進行轉帳充值。 充值成功自動到賬。",
    h2: "溫馨提示",
    h3: "操作流程",
    h4: "請用{name}錢包掃描二維碼",
    h5: "請前往{name}錢包打開粘貼",
    h6: "沒有足够的能量或ETh用於交易",
    h7: "請選擇錢包地址",
    h8: "請選擇TRX錢包交易",
    h9: "沒有足够的能量或TRX用於交易",
    h10: "USDT餘額不足",
    h11: "沒有足够的能量或OKX用於交易",
    approveMsg3: "充值失敗，請換個帳戶試試",
    recharge: "充值失敗該錢包地址USDT流水未達到2000",
  },
  form: {
    address: "收款地址",
    amount: "金額",
  },
};
