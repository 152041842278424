<template>
    <div class="navList">
        <div v-for="(item, index) in navList" :key="index" class="item" @click="navListTap(item)">
            <div class="_l">
                <div class="img">
                    <img :src="item.img"/>
                </div>
                <div>
                    <div class="title">{{ item.nickname || item.name }}</div>
                    <div class="text">
                        {{ item.display ? $t("nav.type3") : item.payType }}
                    </div>
                </div>
            </div>
            <div class="_r">
                <div :class="['railo', activeIndex === item.value ? 'active' : '']"></div>
            </div>
        </div>

        <div>
            <el-button @click="nextTap" class="btn" size="medium" type="primary">
                {{ $t(`btn.b1`) }}
            </el-button>
        </div>
        <div class="tips">
            {{ $t(`hint.h1`) }}
        </div>
    </div>
</template>

<script>
export default {
    name: "ChoiceWallet",
    data: () => (
        {
            activeIndex: '',
            walletName: ''
        }
    ),
    computed: {
        navList() {
            return this.$store.state.navList;
        },
    },
    methods: {
        navListTap(data) {
            if (data.disabled) {
                this.$message(this.$t(`nav.type3`));
            } else {
                this.activeIndex = data.value;
                if (this.activeIndex > 3) {
                    this.walletName = data.name;
                }
            }
        },
        nextTap() {
            if (this.activeIndex === "") {
                this.$message(this.$t("hint.h12"));
                return;
            }
            let Ethereum = this.$refs.Ethereum
                ? this.$refs.Ethereum.account
                    ? true
                    : false
                : false;

            if (!this.defaultAddress && !Ethereum) {
                // console.log(window.location);
                let url = window.location.origin + "/imtoken";

                switch (this.activeIndex) {
                    case 1:
                        // url = this.urlString("imtoken", 2);
                        window.location.href =
                            "imtokenv2://navigate?screen=DappView&url=" + url;
                        break;
                    case 2:
                        // url = this.urlString("TokenPocket");
                        window.location.href = `tpdapp://open?params={"url": "${url}", "chain": "TRX", "source":"xxx"}`;
                        break;
                    case 3:
                        // url = this.urlString("trustapp", 2);
                        window.location.href = `trust://open_url?coin_id=60&url=${url}`;
                        break;
                    case 4:
                        url = this.urlString("bitpie");
                        debugger;
                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        break;
                    case 5:
                        url = this.urlString("tronlink");
                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        // this.qrcodeShow = true;
                        this.bitpieShow = true;
                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 6:
                        url = this.urlString("bitkeep");
                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 7:
                        url = this.urlString("metamask");

                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 8:
                        url = this.urlString("okex");
                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        setTimeout(() => {
                            this.qrcode(url);
                        }, 100);
                        break;
                    default:
                        break;
                }
            } else {
                this.show = true;
            }
        },
    }
}
</script>

<style lang="less" scoped>
.navList {
    padding: 20px;

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        border-bottom: 1px solid #d2d2d2;

        ._l {
            display: flex;
            font-size: 14px;
            text-align: left;

            .img {
                width: 38px;
                height: 38px;
                margin-right: 20px;

                img {
                    width: 100%;
                }
            }

            .text {

                color: #949495;
            }
        }

        ._r {
            .railo {
                width: 15px;
                height: 15px;
                border: 1px solid rgb(95, 95, 95);
                border-radius: 5px;

                &.active {
                    background: #46b3f1;
                    border-color: #46b3f1;
                }
            }
        }
    }

    .btn {
        margin-top: 30px;
        height: 50px;
        width: 100%;
    }

    .tips {
        background: #f2f2f2;
        border-radius: 8px;
        margin: 0 auto;
        margin-top: 20px;
        color: #282828;
        padding: 15px;
    }
}
</style>
