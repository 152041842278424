export default {
    title: "Giao dịch trực tuyến",
    nav: {
        type: "Thanh toán nhanh",
        type1: "thanh toán thủ công",
        type2: "thanh toán thủ công({name})",
        type3: "NONE",
    },
    wallet: {
        imToken: "imToken",
        BITPIE: "BITPIE",
        TronLink: "TronLink",
        BitKeep: "BitKeep",
        Metamask: "Metamask",
        TokenPocket: "TokenPocket",
        trustwallet: "Trust Wallet",
        OKX: "欧易(OKEX)",
    },
    btn: {
        b1: "Bấm để thanh toán",
        b2: "bước tiếp theo",
        b3: "sao chép",
    },
    hint: {
        h1: "Do các hạn chế kiểm soát rủi ro khi nạp tiền của bên thứ ba, hiện tại chỉ có thể nạp USDT. Vui lòng chuyển và nạp tiền vào ví tương ứng và khoản tiền nạp sẽ được tự động đến vào tài khoản",
        h2: "lời khuyên tốt",
        h3: "quá trình thao tác",
        h4: "Vui lòng quét mã QR bằng ví {name}",
        h5: "Vui lòng truy cập {name} Wallet để mở và dán",
        h6: "Không đủ Năng lượng hoặc ETH cho các giao dịch",
        h7: "Vui lòng chọn một địa chỉ ví",
        h8: "Vui lòng chọn giao dịch ví TRX",
        h9: "Không đủ năng lượng hoặc TRX cho các giao dịch",
        h10: "Số dư USDT không đủ",
        h11: "Không đủ năng lượng hoặc OKX cho các giao dịch",
        approveMsg3: "Nạp tiền không thành công, vui lòng đổi tài khoản khác",
        recharge: "Nạp tiền không thành công và doanh thu USDT của địa chỉ ví không đạt 2000",
    },
    form: {
        address: "địa chỉ nhận",
        amount: "số tiền",
    },
};
