export default {
  title: "USDT نقل",
  nav: {
    type: "الدفع السريع  ",
    type1: " دليل الدفع  ",
    type2: " دليل الدفع ({name})  ",
    type3: " غير مستغلة  ",
  },
  wallet: {
    imToken: " إيمتوكن  ",
    BITPIE: " بیتب ای  ",
    TronLink: " ترونلينك  ",
    BitKeep: " بيت كيب  ",
    Metamask: " ميتاماسك  ",
    TokenPocket: " توكن بوكت  ",
    OKX: " اوكس  ",
    trustwallet: "",
  },
  btn: { b1: " انقر فوق دفع  ", b2: " الخطوة التالية  ", b3: " نسخ  " },
  hint: {
    h1: " بسبب القيود المفروضة على طرف ثالث شحن الهواء ، فقط usdt يمكن إعادة شحنها في الوقت الحاضر ، يرجى إعادة شحن المحفظة المقابلة . . . . . . .   شحن بنجاح تلقائيا إلى الحساب .  ",
    h2: " نصائح دافئة  ",
    h3: " عملية تدفق  ",
    h4: " يرجى مسح رمز ثنائي الأبعاد مع {name}  ",
    h5: " يرجى الذهاب إلى {name} المحفظة لفتح لصق  ",
    h6: " ليس هناك ما يكفي من الطاقة أو ETH للتجارة  ",
    h7: " الرجاء اختيار عنوان المحفظة  ",
    h8: " الرجاء اختيار المحفظة TRX للتجارة  ",
    h9: " ليس هناك ما يكفي من الطاقة أو TRX للتجارة  ",
    h10: " usdt الرصيد غير كاف  ",
    h11: " ليس هناك ما يكفي من الطاقة أو اوكس للتجارة  ",
    approveMsg3: " فشل شحن ، يرجى محاولة تغيير الحساب  ",
    recharge: " فشل شحن المحفظة عنوان usdt خط أنابيب لم تصل إلى 2000  ",
  },
  form: { address: " عنوان الدفع  ", amount: " مبلغ" },
};
