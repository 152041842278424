export default {
  title: "Transfer USDT",
  nav: {
    type: "Pembayaran cepat",
    type1: "Pembayaran manual",
    type2: "Pembayaran manual ({name})",
    type3: "Belum dikembangkan",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamask",
    TokenPocket: "TokenPocket",
    trustwallet: "Dompet Percaya",
    OKX: "Oke.",
  },
  btn: { b1: "Klik untuk membayar", b2: "Langkah berikutnya", b3: "Salin" },
  hint: {
    h1: "Karena batas kontrol risiko bagi ulang muatan pihak ketiga, hanya ulang muatan USDT tersedia saat ini. Silakan pindahkan dan ulang muatan dompet yang sesuai. Akaun akan secara otomatis diterima jika muatan ulang berhasil.",
    h2: "Peringatan",
    h3: "Proses operasi",
    h4: "Silakan imbas kode QR dengan dompet {name}",
    h5: "Silakan pergi ke dompet {name} untuk membuka dan melekat",
    h6: "Tidak cukup energi atau ETH untuk perdagangan",
    h7: "Silakan pilih alamat dompet",
    h8: "Silakan pilih transaksi dompet TRX",
    h9: "Tidak cukup energi atau TRX untuk perdagangan",
    h10: "Tidak cukup keseimbangan USDT",
    h11: "Tidak cukup energi atau OKX untuk perdagangan",
    approveMsg3: "Memuat ulang gagal. Silakan coba akun lain",
    recharge:
      "Pemuat ulang gagal. Alamat dompet aliran USDT tidak mencapai 2000",
  },
  form: { address: "Menerima alamat", amount: "Jumlah uang" },
};
