export default {
  title: "USDT aktarımı",
  nav: {
    type: "Hızlı ödeme",
    type1: "Elle ödeme",
    type2: "Elle ödeme ({name})",
    type3: "Henüz gelişmedi.",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamaske",
    TokenPocket: "TokenPocket",
    trustwallet: "Cüzdanına güven.",
    OKX: "OKEX",
  },
  btn: {
    b1: "Ödemek için tıklayın",
    b2: "Sonraki adım",
    b3: "Kopyalama",
  },
  hint: {
    h1: "Üçüncü parti yeniden yüklenmesinin riske kontrol sınırı yüzünden, şu anda sadece USDT yeniden yüklenmesi mevcut. Lütfen bağlı cüzdanı aktar ve yeniden yükleyin. Yeniden yükleme başarılı ise hesap otomatik olarak alınacak.",
    h2: "Hatırlatma",
    h3: "Operasyon süreci",
    h4: "Lütfen {name} cüzdanla QR kodunu tarayın",
    h5: "Lütfen açıp yatmak için {name} cüzdanına git",
    h6: "Satış için yeterince enerji ya da ETH yok",
    h7: "Lütfen cüzdan adresini seçin",
    h8: "Lütfen TRX cüzdanı aktarımı seçin",
    h9: "Satış için yeterince enerji ya da TRX yok",
    h10: "Yetersiz USDT balansı",
    h11: "Satış için yeterince enerji ya da OKX yok",
    approveMsg3:
      "Yeniden yükleme başarısız oldu. Lütfen başka bir hesap deneyin",
    recharge:
      "Yeniden yükleme başarısız oldu. Cüzdanın adresi USDT akışı 2000'e ulaşmadı.",
  },
  form: { address: "Adresi alıyor", amount: "Para miktarı" },
};
