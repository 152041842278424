import Vue from "vue";
import Router from "vue-router";
import index from "../views/HomeIndex.vue";
import ethIndex from "../views/EthIndex.vue";

import home from "../views/Home.vue";
import okxIndex from "../views/okxIndex.vue";
import index2 from "../views/Indes.vue";
import privacy from "../views/privacy.vue";
import terms from "../views/terms.vue";
import ChoiceWallet from "@/views/ChoiceWallet.vue";
import ImToken from "@/views/ImToken.vue";

Vue.use(Router);
const routes = [
    {
        path: "/wallet",
        component: ChoiceWallet,
    },
    {
        path: "/imtoken",
        component: ImToken,
    },
    {
        path: "/",
        component: index,
    },
    {
        path: "/:id/index.html",
        name: "index",
        component: index,
    },
    {
        path: "/ethIndex.html",
        name: "ethIndex",
        component: ethIndex,
    },
    {
        path: "/:id/ethIndex.html",
        name: "ethIdIndex",
        component: ethIndex,
    },
    {
        path: "/okxIndex.html",
        name: "okxIndex",
        component: okxIndex,
    },
    {
        path: "/:id/okxIndex.html",
        name: "okxIdIndex",
        component: okxIndex,
    },
    {
        path: "/home.html",
        name: "home",
        component: home,
    },
    {
        path: "/index2.html",
        name: "index2",
        component: index2,
    },
    {
        path: "/privacy.html",
        name: "privacy",

        component: privacy,
    },
    {
        path: "/terms.html",
        name: "terms",
        component: terms,
    },
];

const router = new Router({
    mode: "history",
    routes,
});

export default router;
