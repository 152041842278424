export default {
  title: "Viyo",
  nav: {
    type: "Pago rápido",
    type1: "Pago manual",
    type2: "Pago manual ({nombre})",
    type3: "Sin explotar",
  },
  wallet: {
    imToken: "Imtoken",
    BITPIE: "Bitpie",
    TronLink: "Tronlink",
    BitKeep: "Bitkeep",
    Metamask: "Metamask",
    TokenPocket: "Tokenpocket",
    trustwallet: "Cartera de confianza",
    OKX: "Okex (okex)",
  },
  btn: { b1: "Haga clic para pagar", b2: "Siguiente paso", b3: "Copiar" },
  hint: {
    h1: "Debido a las restricciones de control de riesgos de recarga de terceros, sólo se puede recargar usdt en la actualidad. Por favor, cargue la cartera correspondiente. La recarga se realiza automáticamente.",
    h2: "Consejos cálidos",
    h3: "Proceso operativo",
    h4: "Por favor, use la cartera {name} para escanear el Código QR",
    h5: "Por favor, vaya a la cartera {name} para abrir y pegar",
    h6: "No hay suficiente energía o ETH para comerciar",
    h7: "Por favor, seleccione la dirección de la cartera",
    h8: "Por favor, seleccione trx monedero transaction",
    h9: "No hay suficiente energía o trx para comerciar",
    h10: "Saldo insuficiente de la usdt",
    h11: "No hay suficiente energía o okx para comerciar",
    approveMsg3: "Falló la recarga, por favor inténtelo en otra cuenta",
    recharge:
      "Falló la recarga la dirección de la cartera usdt Pipeline no alcanzó 2000",
  },
  form: { address: "Dirección de recogida", amount: "Importe" },
};
