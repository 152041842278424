export default {
    title: "USDT转账",
    nav: {
        type: "快捷支付",
        type1: "手动支付",
        type2: "手动支付({name})",
        type3: "暂未开发",
    },
    wallet: {
        imToken: "imToken",
        BITPIE: "BITPIE",
        TronLink: "TronLink",
        BitKeep: "BitKeep",
        Metamask: "Metamask",
        TokenPocket: "TokenPocket",
        trustwallet: "Trust Wallet",
        OKX: "欧易(OKEX)",
    },
    btn: {
        b1: "点击支付",
        b2: "下一步",
        b3: "复制",
    },
    hint: {
        h1: "由于第三方充值风控限制，目前只能USDT充值，请针对相应钱包进行转账充值。充值成功自动到账。",
        h2: "温馨提示",
        h3: "操作流程",
        h4: "请用{name}钱包扫描二维码",
        h5: "请前往{name}钱包打开粘贴",
        h6: "没有足够的能量或ETh用于交易",
        h7: "请选择钱包地址",
        h8: "请选择TRX钱包交易",
        h9: "没有足够的能量或TRX用于交易",
        h10: "USDT余额不足",
        h11: "没有足够的能量或OKX用于交易",
        h12: "请选择支付钱包",
        approveMsg3: "充值失败,请换个账户试试",
        recharge: "充值失败该钱包地址USDT流水未达到2000",
    },
    form: {
        address: "收款地址",
        amount: "金额",
    },
};
